
import { create } from 'zustand';

import { getGridLayouts } from 'utils/grid-layout';

const useOverviewStore = create((set) => ({
    savedLayouts: getGridLayouts(),
    layout: [
        // Left Column
        { i: 'balances', x: 0, y: 0, w: 3, h: 8, minW: 3, maxW: 4, minH: 6, maxH: 30 },
        { i: 'positions', x: 0, y: 8, w: 3, h: 18, minW: 3, maxW: 4, minH: 6, maxH: 30 },

        // Mid Column
        { i: 'ats-overview', x: 3, y: 0, w: 9, h: 6, minW: 3, maxW: 12, minH: 6, maxH: 30 },

        { i: 'top-gainers', x: 3, y: 5, w: 3, h: 7, minW: 2, maxW: 3, minH: 4, maxH: 15 },
        { i: 'top-loosers', x: 6, y: 5, w: 3, h: 7, minW: 2, maxW: 3, minH: 4, maxH: 15 },
        { i: 'top-bnx-score', x: 9, y: 5, w: 3, h: 7, minW: 2, maxW: 3, minH: 4, maxH: 15 },

        { i: 'trades-overview', x: 3, y: 12, w: 9, h: 13, minW: 3, maxW: 12, minH: 9, maxH: 30 },

        // Right Column
        { i: 'market-sentiment', x: 12, y: 0, w: 3, h: 8, minW: 2, maxW: 4, minH: 8, maxH: 8 },
        { i: 'articles', x: 12, y: 8, w: 3, h: 17, minW: 3, maxW: 4, minH: 6, maxH: 30 },
    ],
    setLayout: (newLayout) => set({ layout: newLayout }),

    loadingAssets: true,
    setLoadingAssets: (status) => set({ loadingAssets: status }),

    userAssets: {},
    setUserAssets: (assets) => set({ userAssets: assets }),

    userPositionsAux: {},
    setUserPositionsAux: (positions) => set({ userPositionsAux: positions }),

    portfolioPositions: [],
    setPortfolioPositions: (positions) => set({ portfolioPositions: positions }),

    hideSmallPositions: true,
    setHideSmallPositions: (status) => set({ hideSmallPositions: status }),

    showWallet: true,
    setShowWallet: (status) => set({ showWallet: status }),

    showWalletInfo: false,
    setShowWalletInfo: (status) => set({ showWalletInfo: status }),

    tickerTapeCoins: [],
    setTickerTapeCoins: (coins) => set({ tickerTapeCoins: coins }),

    topGainers: [],
    seTopGainers: (gainers) => set({ topGainers: gainers }),

    topLoosers: [],
    setTopLoosers: (loosers) => set({ topLoosers: loosers }),

    topScores: [],
    setTopScores: (scores) => set({ topScores: scores }),

    loadingTickers: true,
    setLoadingTickers: (status) => set({ loadingTickers: status }),

    loadingTopScores: true,
    setLoadingTopScores: (status) => set({ loadingTopScores: status }),

    marketSentiment: null,
    setMarketSentiment: (sentiment) => set({ marketSentiment: sentiment }),

    showMarketSentimentInfo: false,
    setShowMarketSentimentInfo: (status) => set({ showMarketSentimentInfo: status }),
}));


export default useOverviewStore;
