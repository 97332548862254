// usePriceWatcher.js - Otimizado
import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

import PriceWatcher from '../utils/priceWatcher';
import { exchangesTickersGet } from '../apis/endpoints/exchangesTickersGet';

const usePriceWatcher = (
    isUserAuthenticated,
    userExchanges,
    appSelectedCurrency,
    appSelectedTimeframe,
    appTop100Filter,
    setTickers,
    connectWs = true
) => {
    const priceWatcherRef = useRef(null);
    const debouncedSetTickers = useRef(debounce(setTickers, 300)); // Atualiza no máximo a cada 300ms
    const previousDependencies = useRef({});

    useEffect(() => {
        // Preparar exchanges
        const userExchangesCommaSeparated = userExchanges.map((exchange) => exchange.slug).join(',');

        // Buscar tickers iniciais
        exchangesTickersGet({
            exchangeSlugs: userExchangesCommaSeparated,
            timeframe: appSelectedTimeframe
        }).then((result) => {
            const { response, isSuccess } = result;

            if (isSuccess) setTickers(response);
        });
    }, [userExchanges, appSelectedCurrency, appSelectedTimeframe, appTop100Filter]);

    useEffect(() => {
        // Lazy initialization do PriceWatcher
        if (!priceWatcherRef.current)
            priceWatcherRef.current = new PriceWatcher();

        // Verificar se dependências mudaram
        const depsChanged = JSON.stringify(previousDependencies.current) !==
            JSON.stringify({
                isUserAuthenticated,
                userExchanges,
                appSelectedCurrency,
                appSelectedTimeframe,
                appTop100Filter
            });

        if (depsChanged && isUserAuthenticated) {
            previousDependencies.current = {
                isUserAuthenticated,
                userExchanges,
                appSelectedCurrency,
                appSelectedTimeframe,
                appTop100Filter
            };

            // Parar o WebSocket anterior, se existente
            if (priceWatcherRef.current != null) {
                priceWatcherRef.current.stop();
            }

            // Preparar exchanges
            const userExchangesCommaSeparated = userExchanges.map((exchange) => exchange.slug).join(',');

            if (connectWs) {
                console.log('Starting price watcher');

                // Iniciar o watcher
                priceWatcherRef.current.start({
                    userExchanges: userExchangesCommaSeparated,
                    targetCurrency: appSelectedCurrency,
                    targetTimeframe: appSelectedTimeframe,
                    onUpdate: (data) => {
                        debouncedSetTickers.current(data);
                    },
                });
            }
        }
    }, [isUserAuthenticated]);
};

export default usePriceWatcher;
