import React from 'react';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';

// Base Components
import { Panel, Row, Title, Column } from 'components/imports';

// List Components
import { CoinsVariationList, TrendScoresList } from 'components/imports';

// Tradução
import { useTranslation } from 'react-i18next';
import { buildExchangesStorageUrl } from 'icons/imports';


const TopListPanel = (props) => {
    const { t } = useTranslation();

    return (
        <Panel id={props.id} fill='all'>
            <Row
                fill='width'
                j='between'
            >
                <Title variation='tertiary' txt={t(props.title)} />

                <Row>
                    <img className='icon-svg' src={buildExchangesStorageUrl(props.selectedExchange)} />

                    <p className='money-currency'>{props.selectedTimeframe}</p>
                </Row>
            </Row>

            <hr />

            <Column scroll fill='all'>

                {!props.isUserAuthenticated || (props.id == 'top-bnx-score' && props.list.length == 0) || props.loadingTickers ? (
                    <Row id={props.id + '-skeleton'}
                        fill='width'
                        j='between'
                    >
                        <Row>
                            <Skeleton
                                className='skeleton'
                                containerClassName='avatar-skeleton'
                                circle
                                count={3}
                                height='2rem'
                                style={{ marginBottom: '.25rem' }}
                                width='2rem'
                            />

                            <Skeleton
                                className='skeleton'
                                count={3}
                                height={'32px'}
                                style={{ marginBottom: '.25rem' }}
                                width={'3rem'}
                            />
                        </Row>

                        <Skeleton
                            className='skeleton'
                            count={3}
                            height={'2rem'}
                            style={{ marginBottom: '.25rem' }}
                            width={'5rem'}
                        />
                    </Row>
                ) : (
                    props.id == 'top-bnx-score'
                        ?
                        <TrendScoresList
                            id={props.id + '-list'}
                            items={props.list}
                            selectedCurrency={props.selectedCurrency}
                        />
                        :
                        <CoinsVariationList
                            id={props.id + '-list'}
                            items={props.list}
                            selectedCurrency={props.selectedCurrency}
                        />
                )}
            </Column>
        </Panel>);
};


TopListPanel.propTypes = {
    loadingTickers: PropTypes.bool,
    list: PropTypes.array,
    id: PropTypes.string,
    isUserAuthenticated: PropTypes.bool,
    selectedExchange: PropTypes.string,
    selectedTimeframe: PropTypes.string,
    selectedCurrency: PropTypes.string,
    title: PropTypes.string
};


export default TopListPanel;
