import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ConfirmSvg, PencilSvg, TelegramSvg, WhatsAppSvg } from 'icons/imports';

import { SwitchableIcon } from 'components/imports';

import './BotResume.css';


function BotResume(props) {

    const { t } = useTranslation();

    const [editBot,] = useState(false);

    var icon = null, title = '';

    var status = 'disabled';

    switch (props.variation) {
        case 'telegram':
            title = 'Telegram';
            icon = <TelegramSvg className='icon-svg social-media-icon'></TelegramSvg>;
            break;
        case 'whatsapp':
            title = 'WhatsApp';
            icon = <WhatsAppSvg className='icon-svg social-media-icon'></WhatsAppSvg>;
    }

    var pencilSvg = <PencilSvg className='icon-svg' title='Editar nome completo'></PencilSvg>;
    var confirmSvg = <ConfirmSvg className='icon-svg' title='Editar nome completo'></ConfirmSvg>;

    const handleToggleEditBot = () => {
        console.log('TODO: handleToggleEditBot');
    };

    return (
        <div className='container bot-card'>
            <div className='bot-card-title'>
                <div>
                    {icon}
                    <p>{title}</p>
                </div>
                <SwitchableIcon
                    className='edit-icon'
                    onImage={confirmSvg}
                    offImage={pencilSvg}
                    isOn={editBot}
                    onToggle={handleToggleEditBot}
                />
            </div>
            <div className='bot-card-content'>
                <span>
                    <p>Status:</p>
                    <p>{t(status)}</p>
                </span>
                <span>
                    <p>Para:</p>
                    <b>-</b>
                </span>
            </div>
        </div>
    );
}


BotResume.propTypes = {
    variation: PropTypes.string.isRequired,
};


export default BotResume;
