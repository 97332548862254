/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import i18n from 'locales/i18n';
import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import dayjs from 'dayjs';

import { Select, DatePicker } from 'antd';

// Base Componetns
import {
    Page, Layout, Loading, MessagePopup, Row, Column, Panel,
    Title, Button
} from 'components/imports';

// Icon Buttons Components
import { SwitchableIcon } from 'components/imports';

// List Components
import { OrdersTable, SignalsTable } from 'components/imports';

// Endpoints
import { signalsUsersMeGet, ordersMeGet } from 'apis/imports';

import { ExchangeConnectionSvg } from 'images/imports';

import { RefreshSvg } from 'icons/imports';

import { timeframes } from 'constants';

import { getAssetBase } from 'utils/assets-bases';

import { getUserConnectedExchanges } from 'utils/exchanges';
import { getTrendsMarketcaps } from 'utils/trends-marketcaps';

import {
    getSelectedCurrency, getSelectedExchange, getSelectedTimeframe, getLanguage,
    getTheme, getTop100Filter
} from 'utils/cookies';


import usePriceWatcher from 'hooks/usePriceWatcher';

import './Trades.css';


const Trades = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'trades';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang] = useState(getLanguage());
    const [appTheme] = useState(getTheme());
    const [appSelectedExchange, setAppSelectedExchange] = useState(getSelectedExchange());
    const [appSelectedCurrency, setAppSelectedCurrency] = useState(getSelectedCurrency());
    const [appSelectedTimeframe, setAppSelectedTimeframe] = useState(getSelectedTimeframe());
    const [appTop100Filter, setAppTop100Filter] = useState(getTop100Filter());

    const [changingFilters, setChangingFilters] = useState(false);

    const [popUpDuration,] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [userExchanges, setUserExchanges] = useState(getUserConnectedExchanges({}));

    const [tickers, setTickers] = useState({});

    const [loadingTickers, setLoadingTickers] = useState(false);
    const [loadingPnLHistory, setLoadingPnLHistory] = useState(false);
    const [loadingTradesPlot, setLoadingTradesPlot] = useState(false);
    const [loadingSignals, setLoadingSignals] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(false);

    const [signalsData, setSignalsData] = useState([]);
    const [ordersData, setOrdersData] = useState([]);

    const { RangePicker } = DatePicker;

    const [exchangesFilter, setExchangesFilter] = useState([]);
    const [pairsFilter, setPairsFilter] = useState([]);
    const [timeframesFilter, setTimeframesFilter] = useState([]);
    const [sideFilter, setSideFilter] = useState(null);
    const [dateRangeFilter, setDateRangeFilter] = useState([dayjs().startOf('day'), dayjs()]);

    const [filterButtonState, setFilterButtonState] = useState('enabled');

    const refreshPnLHistoryIcon = <RefreshSvg className={'icon-svg refresh' + (loadingPnLHistory ? ' spinning' : '')} />;
    const refreshSignalsIcon = <RefreshSvg className={'icon-svg refresh' + (loadingSignals ? ' spinning' : '')} />;
    const refreshOrdersIcon = <RefreshSvg className={'icon-svg refresh' + (loadingOrders ? ' spinning' : '')} />;


    usePriceWatcher(isUserAuthenticated, userExchanges, appSelectedCurrency, appSelectedTimeframe, appTop100Filter, setTickers);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('trade.p')} - Smart Trade`;
            getTrades();
        }
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {

        if (!(appSelectedExchange in tickers)) return;

        let trendsMarketcaps = {};

        if (appTop100Filter) {
            trendsMarketcaps = getTrendsMarketcaps({ asObject: true });
        }

        let tickerList = Object.entries(tickers[appSelectedExchange]).reduce((tickers, [pair, ticker]) => {
            const [base,] = pair.split('/');

            if (base.includes('USD')) return tickers;

            ticker.symbol = base;

            if (appTop100Filter && trendsMarketcaps) {
                let baseAsset = getAssetBase({ symbol: base });
                if (!(baseAsset.slug in trendsMarketcaps)) return tickers;
            }

            ticker.pnl = ticker.pnls[appSelectedTimeframe][1];

            tickers.push(ticker);

            return tickers;
        }, []);

        if (!tickerList.length) return;

        let exchangeTickers = tickers[appSelectedExchange];

        exchangeTickers;
    }, [tickers]);


    useEffect(() => {
        if (!changingFilters) setChangingFilters(true);
    }, [appTop100Filter, appSelectedExchange, appSelectedCurrency, appSelectedTimeframe]);


    useEffect(() => {

        if (!loadingTickers) setLoadingTickers(changingFilters);

    }, [changingFilters]);


    useEffect(() => {
        if (!dateRangeFilter || dateRangeFilter.length !== 2 || !dateRangeFilter[0] || !dateRangeFilter[1])
            setFilterButtonState('disabled');
        else
            setFilterButtonState('enabled');

    }, [dateRangeFilter]);


    const getTrades = () => {
        getSignals();
        getOrders();
    };


    const getSignals = async () => {

        if (!isUserAuthenticated || filterButtonState !== 'enabled') return;

        setLoadingSignals(true);

        const [since, until] = dateRangeFilter;

        const args = {
            exchanges: exchangesFilter,
            pairs: pairsFilter,
            timeframes: timeframesFilter,
            side: sideFilter,
            since: since.valueOf(),
            until: until.valueOf()
        };

        try {
            const result = await signalsUsersMeGet(args);

            if (result.isSuccess) {
                const response = result.response.items.map((item, index) => ({
                    isAtsOrder: item.is_ats_order,
                    key: index,
                    timestamp: item.timestamp,
                    exchangeSlug: item.exchange_slug,
                    pair: item.pair,
                    side: item.side,
                    price: item.price,
                    timeframe: item.timeframe,
                    pnl: item.pnl
                }));

                setSignalsData(response);
            } else {
                setPopUpLevel('error');
                setPopUpText(t('error.fetch-signals'));
            }
        } catch (error) {
            setPopUpLevel('error');
            setPopUpText(t('network-error'));
        } finally {
            setLoadingSignals(false);
        }
    };

    const getOrders = async () => {

        if (!isUserAuthenticated || filterButtonState !== 'enabled') return;

        setLoadingOrders(true);

        const [since, until] = dateRangeFilter;

        const args = {
            exchanges: exchangesFilter,
            pairs: pairsFilter,
            side: sideFilter,
            since: since.valueOf(),
            until: until.valueOf()
        };

        try {
            const result = await ordersMeGet(args);

            if (result.isSuccess) {
                const response = result.response.items.map((item, index) => {

                    let { timestamp, pair, side, price, pnl, status, amount, filled } = item;

                    return {
                        key: index,
                        isAtsOrder: item.is_ats_order,
                        orderId: item.order_id,
                        exchangeSlug: item.exchange_slug,
                        timestamp,
                        pair,
                        side,
                        price,
                        pnl,
                        status,
                        amount,
                        filled
                    };
                });

                setOrdersData(response);
            } else {
                setPopUpLevel('error');
                setPopUpText(t('error.fetch-orders'));
            }
        } catch (error) {
            setPopUpLevel('error');
            setPopUpText(t('network-error'));
        } finally {
            setLoadingOrders(false);
        }
    };

    const buildSignalsExchangesFilter = () =>
        userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug,
        }));


    const buildSignalsPairsFilter = () => [
        { value: 'SUI/USDT', label: 'SUI/USDT' },
        { value: 'STX/USDT', label: 'STX/USDT' },
        { value: 'JUP/USDT', label: 'JUP/USDT' },
        { value: 'WLD/USDT', label: 'WLD/USDT' },
    ];


    const buildSignalsTimeframesFilter = () =>
        timeframes.map((timeframe) => ({
            label: timeframe,
            value: timeframe,
        }));


    const buildSignalsSideFilter = () => [
        { label: t('all-sides'), value: null },
        { label: t('buy.v'), value: 'buy' },
        { label: t('sell.s'), value: 'sell' },
    ];


    const buildOrdersExchangesFilter = () =>
        userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug,
        }));

    const buildOrdersPairsFilter = () => [
        { value: 'SUI/USDT', label: 'SUI/USDT' },
        { value: 'STX/USDT', label: 'STX/USDT' },
        { value: 'JUP/USDT', label: 'JUP/USDT' },
        { value: 'WLD/USDT', label: 'WLD/USDT' },
    ];

    const buildOrdersSideFilter = () => [
        { label: t('all-sides'), value: null },
        { label: t('buy.v'), value: 'buy' },
        { label: t('sell.s'), value: 'sell' },
    ];

    return (
        <Page id={pageId}>
            <Loading id={'overview'} />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                setIsUserAuthenticated={setIsUserAuthenticated}
                setSelectedExchange={setAppSelectedExchange}
                setSelectedCurrency={setAppSelectedCurrency}
                setSelectedTimeframe={setAppSelectedTimeframe}
                setAppTop100Filter={setAppTop100Filter}
                setUserExchanges={setUserExchanges}
                showToolbar={true}
                showHeader={true}
            >
                <Row
                    a='start'
                    className='main-row'
                    fill='width'
                    j='center'
                >
                    <Column
                        id='table-col'
                        w='16'
                    >
                        {/* <Panel id='line-plot-panel' fill='width' fit='height'>
                            <Row fill='width' j='between'>
                                <Title id='line-plot-title' txt={t('pnl-through-time')} variation='tertiary' />

                                <SwitchableIcon
                                    id='refresh-trades'
                                    onToggle={() => { }}
                                    staticImage={refreshPnLHistoryIcon}
                                />
                            </Row>
                        </Panel> */}

                        <Panel id='filters-panel' fill='width' fit='height'>
                            <Row fill='width'>
                                <Select
                                    mode='multiple'
                                    maxCount={1}
                                    placeholder={t('exchange.p')}
                                    variant='borderless'
                                    style={{ flex: 1 }}
                                    options={buildSignalsExchangesFilter()}
                                    onChange={(value) => setExchangesFilter(value)}
                                />

                                <Select
                                    mode='tags'
                                    maxCount={1}
                                    placeholder={t('pair.p')}
                                    variant='borderless'
                                    style={{ flex: 1 }}
                                    options={buildSignalsPairsFilter()}
                                    onChange={(value) => setPairsFilter(value)}
                                />

                                <Select
                                    mode='multiple'
                                    maxCount={1}
                                    placeholder={t('timeframe.p')}
                                    variant='borderless'
                                    style={{ flex: 1 }}
                                    options={buildSignalsTimeframesFilter()}
                                    onChange={(value) => setTimeframesFilter(value)}
                                />

                                <Select
                                    placeholder={t('side.s')}
                                    variant='borderless'
                                    defaultValue={['all']}
                                    style={{ flex: 1 }}
                                    options={buildSignalsSideFilter()}
                                    onChange={(value) => setSideFilter(value == 'all' ? null : value)}
                                />

                                <RangePicker
                                    showTime={{ format: 'HH:mm' }}
                                    variant='borderless'
                                    onChange={(value) => setDateRangeFilter(value)}
                                    format="YYYY-MM-DD HH:mm"
                                    defaultValue={dateRangeFilter}
                                />

                                <Button
                                    id='apply-filters'
                                    variation='primary'
                                    state={filterButtonState}
                                    onClick={() => getTrades()}
                                >
                                    {t('apply')}
                                </Button>
                            </Row>
                        </Panel>

                        <Panel id='signals-panel' fill='all'>
                            <Row fill='width' j='between'>
                                <Title id='signals-title' txt={t('signal.p')} variation='tertiary' />

                                <SwitchableIcon
                                    id='refresh-signals'
                                    onToggle={() => getSignals()}
                                    staticImage={refreshSignalsIcon}
                                />
                            </Row>

                            {
                                loadingSignals || !isUserAuthenticated
                                    ? (<Row
                                        fill='width'
                                        j='between'
                                    >
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'10rem'}
                                        />
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'10rem'}
                                        />
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'5rem'}
                                        />
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'5rem'}
                                        />
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'5rem'}
                                        />
                                    </Row>)
                                    : (signalsData.length === 0
                                        ? (<Row
                                            a='center'
                                            fill='all'
                                            j='center'
                                        >
                                            <Column
                                                a='center'
                                                fit='all'
                                                j='center'
                                            >
                                                <img className='empty-img' src={ExchangeConnectionSvg} />

                                                <p>{t('message-signals-empty')}</p>
                                            </Column>
                                        </Row>)
                                        : <SignalsTable id='signals'
                                            exchanges={userExchanges}
                                            items={signalsData}
                                            lang={appLang}
                                        />
                                    )
                            }
                        </Panel>

                        <Panel id='orders-panel' fill='all'>
                            <Row fill='width' j='between'>
                                <Title id='orders-title' txt={t('order.p')} variation='tertiary' />

                                <SwitchableIcon
                                    id='refresh-ordewrs'
                                    onToggle={() => getOrders()}
                                    staticImage={refreshOrdersIcon}
                                />
                            </Row>

                            {
                                loadingOrders || !isUserAuthenticated
                                    ? (<Row
                                        fill='width'
                                        j='between'
                                    >
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'10rem'}
                                        />
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'10rem'}
                                        />
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'5rem'}
                                        />
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'5rem'}
                                        />
                                        <Skeleton
                                            className='skeleton'
                                            count={4}
                                            height={'2.5rem'}
                                            style={{ marginBottom: '.75rem' }}
                                            width={'5rem'}
                                        />
                                    </Row>)
                                    : (ordersData.length === 0
                                        ? (<Row
                                            a='center'
                                            fill='all'
                                            j='center'
                                        >
                                            <Column
                                                a='center'
                                                fit='all'
                                                j='center'
                                            >
                                                <img className='empty-img' src={ExchangeConnectionSvg} />

                                                <p>{t('message-signals-empty')}</p>
                                            </Column>
                                        </Row>)
                                        : <OrdersTable id='orders'
                                            exchanges={userExchanges}
                                            items={ordersData}
                                            lang={appLang}
                                        />
                                    )
                            }
                        </Panel>
                    </Column>
                </Row>
            </Layout>
        </Page>
    );
};

export default Trades;
