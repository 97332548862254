

export const getGridLayouts = () => {

    let gridLayout = localStorage.getItem('grid_layout');

    if (!gridLayout || gridLayout == 'undefined') return {};
    else gridLayout = JSON.parse(gridLayout);

    return gridLayout;
};


export const setGridLayouts = (gridLayout) => {

    localStorage.setItem('grid_layout', JSON.stringify(gridLayout));
};