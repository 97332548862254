
import { atsModesPairsGet } from 'apis/imports';

export const fetchAndSaveInStorageUserAtsPairs = ({mode = 'default'}) => {
    atsModesPairsGet({ mode }).then(result => {
        let { response } = result;

        localStorage.setItem('ats_pairs_' + mode,
            JSON.stringify(response.items.map(item => item.exchange_slug + ':' + item.pair)));
    });
};

// Nota: caso houver slugs com um mesmo symbol, assume-se que serão em corretoras diferentes,
// então se caso for necessário, informe o exchange_slug e filtre as exceções
export const getUserAtsPairs = ({ mode = 'default', asObject = false }) => {

    let atsPairs = localStorage.getItem('ats_pairs_' + mode);

    if (!atsPairs || atsPairs == 'undefined') atsPairs = {};
    else atsPairs = JSON.parse(atsPairs);

    if (asObject) return atsPairs;
    return Object.values(atsPairs || {});
};