import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Layout, Page } from 'components/imports';

import useGlobalStore from 'stores/GlobalStore';

import './EventsCalendar.css';


const EventsCalendar = () => {

    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'events-calendar';

    const {
        isUserAuthenticated,
        setIsUserAuthenticated
    } = useGlobalStore();


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('events-calendar')} - Smart Trade`;

            isUserAuthenticated;
        }
    }, [location.pathname]);


    return (
        <Page id={pageId} >
            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='basic'
                setIsUserAuthenticated={setIsUserAuthenticated}
                showHeader={true}
                showToolbar={true}
                dialogConfirm={false}
                maximizedScreen={false}
            >
                <p className='side-label'>TODO:</p> <p>Develop events calendar</p>
            </Layout>
        </Page >
    );
};

export default EventsCalendar;
