
import React, { useState, useEffect } from 'react';

import i18n from 'locales/i18n';
// JQuery
import $ from 'jquery';

// React Router
import { useLocation } from 'react-router-dom';

// React Translations
import { useTranslation } from 'react-i18next';

// React Tours
import { useTour } from '@reactour/tour';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Base
import { Column, Layout, Loading, Panel, Page, Row } from 'components/imports'; // Layout
import { Badge, Button, Date, Input, Link } from 'components/imports'; // Elements

// Icon Buttons
import { SentimentIcon, SwitchableIcon } from 'components/imports';

// List
import { NewsArticlesList } from 'components/imports';

// Icons
import { LinkSvg, RefreshSvg } from 'icons/imports'; // Icons
import { getIcon } from 'icons/imports'; // Functions

// Images
import { DefaultNewsBannerPng } from 'images/imports'; // Images
import { getImage } from 'images/imports'; // Functions


// API Endpoints
import { newsGet, usersMeGet } from 'apis/imports';

import { getLanguage, getTheme } from 'utils/cookies';


// Styles
import './News.css';


const News = () => {

    /**
     * Renders the News component.
     *
     * @return {JSX.Element} The rendered News component.
     */

    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'news';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang,] = useState(getLanguage());
    const [appTheme,] = useState(getTheme());

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { setIsOpen, setSteps, setCurrentStep } = useTour();

    const [coins,] = useState([]);
    const [, setSearchedCoins] = useState([]);
    const [searchCoins, setSearchCoins] = useState('');

    const [news, setNews] = useState([]);
    const [articleEmphasis, setArticleEmphasis] = useState({});
    const [filteredNews, setFilteredNews] = useState([]);
    const [searchedNews, setSearchedNews] = useState([]);
    const [searchNews, setSearchNews] = useState('');

    const [filterNewsPeriod, setFilterNewsPeriod] = useState('');
    const [filterTrendsList,] = useState('');

    const [loadingNews, setLoadingNews] = useState(true);

    const linkIcon = <LinkSvg className='icon-svg' />;
    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (loadingNews ? ' spinning' : '')} />;

    useEffect(() => {

        if (location.pathname === `/${pageId}`) {
            document.title = `${t('news')} - Smart Trade`;

            // setSteps(stepTrendAndNews);
            setCurrentStep(0);
            setTimeout(() => { setIsOpen(true); }, 2000);

            $('body').css('overflow', 'hidden');

            getUserMe();
            getNews();
        }

    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {
        if (Object.keys(articleEmphasis).length > 0)
            $('.loading').removeClass('loading');
    }, [articleEmphasis]);


    useEffect(() => {
        $('.button.tertiary.selected').removeClass('selected');

        $(`#last-news-f-${filterNewsPeriod}`).addClass('selected');

        let startTs,
            endTs = new window.Date().getTime();

        switch (filterNewsPeriod) {
            case '2':
                endTs = endTs - (24 * 60 * 60 * 1000); // - 24 hours
                startTs = endTs - (6.5 * 24 * 60 * 60 * 1000); // - 7 days
                break;
            case '3':
                endTs = endTs - (7 * 24 * 60 * 60 * 1000); // - 7 days
                startTs = endTs - (30 * 24 * 60 * 60 * 1000); // - 30 days
                break;
            default:
                startTs = endTs - (24 * 60 * 60 * 1000); // - 24 hours
                break;
        }

        // milliseconds to seconds
        startTs = parseInt(startTs / 1000);
        endTs = parseInt(endTs / 1000);

        if (filterNewsPeriod == '0') {
            setFilteredNews(news);
        }
        else {
            let filteredNews = news.reduce((acc, item) => {
                if (item.publishedAt >= startTs && item.publishedAt <= endTs)
                    acc.push(item);
                return acc;
            }, []);
            setFilteredNews(filteredNews);
        }

    }, [filterNewsPeriod]);


    useEffect(() => {

        $('#trends-filters button.selected').removeClass('selected');
        $(`#trends-f-${filterTrendsList}`).addClass('selected');

        switch (filterTrendsList) {
            case '0':
                $(`#${pageId + '-trends-list-0'}`).removeClass('d-none');
                $(`#${pageId + '-trends-list-1'}`).addClass('d-none');
                break;
            case '1':
                $(`#${pageId + '-trends-list-0'}`).addClass('d-none');
                $(`#${pageId + '-trends-list-1'}`).removeClass('d-none');
                break;
            default:
                break;
        }

    }, [filterTrendsList]);


    useEffect(() => {

        let txt = searchNews.trim().toLowerCase();

        if (txt == '') setSearchedNews(filteredNews);
        else {
            let found = filteredNews.reduce((acc, item) => {
                let source = item.source.toLowerCase();
                let title = item.title.toLowerCase();
                if (source.includes(txt) || title.includes(txt)) acc.push(item);
                return acc;
            }, []);

            setSearchedNews(found);
        }
    }, [filteredNews, searchNews]);


    useEffect(() => {

        let txt = searchCoins.trim().toLowerCase();

        if (txt == '') setSearchedCoins(coins);
        else {
            let found = coins.reduce((acc, item) => {
                let coin = item.coin.toLowerCase(),
                    name = item.title.toLowerCase().split(';/')[0].trim();
                if (coin.includes(txt) || name.includes(txt)) acc.push(item);
                return acc;
            }, []);
            setSearchedCoins(found);
        }
    }, [coins, searchCoins]);


    const getUserMe = async () => {

        if (!isUserAuthenticated) return;

        const result = await usersMeGet();

        if (result.isSuccess) {
            let { fullname, username, role } = result.response;

            /*
            if (mfa_auth_app) setSteps(stepsNews);
            else setSteps(stepsNewsWithoutMfa);
            setTimeout(() => { setCurrentStep(0); setIsOpen(true); }, 2000);
            */

            $('#user-fullname').text(fullname);
            $('#user-email').text(username);
            if (role === 'admin') $('#user-permission').show();

            setSearchCoins('');
        }
    };


    const getNews = async () => {

        if (!isUserAuthenticated) return;

        setLoadingNews(true);

        const result = await newsGet({ period: 'month', limit: 1000 });

        setLoadingNews(false);

        if (result.isSuccess) {

            let items = result.response.items.reduce((acc, item) => {

                if (!item.banner) item.banner = DefaultNewsBannerPng;

                item.timestamp = item.publishedAt * 1000;

                item.sourceIcon = getIcon(item.source);
                item.sourceImage = getImage(item.source);

                item.authors = item.authors.join(' • ');
                item.authorsMin = item.authors.length >= 30 ? item.authors.substring(0, 27) + '...' : item.authors;
                item.authors = 'By ' + item.authors;

                item.id = `article_${item.publishedAt}_${item.api}_${item.source}`;

                acc.push(item);
                return acc;
            }, []);

            setNews(items);
            setArticleEmphasis(localStorage.getItem('article') ? items.find(item => item.id === localStorage.getItem('article')) : items[0]);
            setFilterNewsPeriod('1');

            if (localStorage.getItem('article')) localStorage.removeItem('article');
        }
    };


    const handleSearchNewsChange = (event) => {
        setSearchNews(event.target.value);
    };


    const selectNewsCard = (id) => {
        $('.news-card').removeClass('selected');
        $(`#${id}`).addClass('selected');
        setArticleEmphasis(news.find(item => item.id === id));
    };


    return (
        <Page id={pageId} >
            <Loading id={'news-loading-overlay'} />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                setIsUserAuthenticated={setIsUserAuthenticated}
                showHeader={true}
                showToolbar={true}
                dialogConfirm={false}
                maximizedScreen={false}
            >
                <Row className='main-row'
                    a='unset'
                    fill='width'
                >
                    <Panel id='article-container'
                        fill='height'
                        fit='width'
                    >
                        <Row fill='width' j='between'>
                            <Input id='search-news'
                                className='fill-width'
                                onChange={handleSearchNewsChange}
                                placeholder={t('search.news')}
                                variation='searchbar'
                                value={searchNews}
                            />

                            <SwitchableIcon
                                id='refresh-balances'
                                onToggle={() => getNews()}
                                staticImage={refreshIcon}
                            />
                        </Row>

                        <Row
                            fill='width'
                            j='center'
                        >
                            <Button id='last-news-f-1'
                                key='last-news-f-1'
                                onClick={() => setFilterNewsPeriod('1')}
                                variation='tertiary'
                            >
                                {t('last.hour.p').replace('{}', 24)}
                            </Button>

                            <Button id='last-news-f-2'
                                key='last-news-f-2'
                                onClick={() => setFilterNewsPeriod('2')}
                                variation='tertiary'
                            >
                                {t('last.day.p').replace('{}', 7)}
                            </Button>

                            <Button id='last-news-f-3'

                                key='last-news-f-3'
                                onClick={() => setFilterNewsPeriod('3')}
                                variation='tertiary'
                            >
                                {t('last.day.p').replace('{}', 30)}
                            </Button>
                        </Row>

                        {loadingNews ?
                            [0, 1].map(() => (<>
                                <hr />

                                <Column p='pri'>
                                    <Skeleton
                                        height='5rem'
                                        width='23rem'
                                    />

                                    <Row>
                                        <Skeleton
                                            height='1.5rem'
                                            width='1.5rem'
                                        />

                                        <Skeleton
                                            height='1.25rem'
                                            width='5rem'
                                        />
                                    </Row>

                                    <Column g='0'>
                                        <Skeleton
                                            height='1.25rem'
                                            width='23rem'
                                        />

                                        <Skeleton
                                            height='1.25rem'
                                            width='10rem'
                                        />
                                    </Column>
                                </Column>
                            </>)) : (
                                <NewsArticlesList id='articles-list'
                                    items={searchedNews}
                                    lang={appLang}
                                    onItemClick={selectNewsCard}
                                />
                            )}
                    </Panel>

                    <Panel id='article-emphasis'
                        fill='all'
                    >
                        <Column id='article-scroll'
                            fill='all'
                            scroll
                        >
                            {articleEmphasis?.banner
                                ? <img id='banner' onError={() => this.style.display = 'none'} src={articleEmphasis.banner} />
                                : <Skeleton
                                    className='skeleton'
                                    height={'25rem'}
                                    width={'100%'}
                                />
                            }

                            <Row id='article-header'
                                fill='width'
                                j='between'
                                m='pri'
                                g='2'
                            >
                                {articleEmphasis?.sourceImage
                                    ? <img
                                        className='source-image'
                                        onError={() => this.style.display = 'none'}
                                        src={articleEmphasis.sourceImage}
                                        title={articleEmphasis.source ? 'See the full article on ' + articleEmphasis.source : ''}
                                    />
                                    : (articleEmphasis?.source
                                        ? <b>{articleEmphasis?.source}</b>
                                        : <Skeleton
                                            className='skeleton'
                                            height={'2rem'}
                                            width={'12rem'}
                                        />)
                                }

                                <Column a='end' g='0'>
                                    {articleEmphasis?.authors
                                        ? <b>
                                            <p id='authors'
                                                title={articleEmphasis.authors}
                                            >{articleEmphasis.authorsMin}</p>
                                        </b>
                                        : <Skeleton
                                            className='skeleton'
                                            height={'1rem'}
                                            width={'12rem'}
                                        />
                                    }

                                    {articleEmphasis?.timestamp
                                        ? <Date timestamp={articleEmphasis.timestamp} lang={appLang} />
                                        : <Skeleton
                                            className='skeleton'
                                            height={'1rem'}
                                            width={'10rem'}
                                        />
                                    }
                                </Column>
                            </Row>

                            <Row
                                a='start'
                                fill='width'
                                j='between'
                                m='pri'
                            >
                                <Column fill='width'>
                                    {articleEmphasis?.title
                                        ? <h3>{articleEmphasis.title}</h3>
                                        : <Skeleton
                                            className='skeleton'
                                            height={'1.5rem'}
                                            width={'25rem'}
                                        />
                                    }

                                    {articleEmphasis.summary
                                        ? <p id='summary'>{articleEmphasis.summary}</p>
                                        : <Skeleton
                                            className='skeleton'
                                            height={'1rem'}
                                            width={'40rem'}
                                        />
                                    }

                                    {articleEmphasis.url
                                        ? <Link
                                            onClick={() => {
                                                let result = confirm(t('ask-confirm-website-redirect').replace('{source}', articleEmphasis.source));

                                                if (!result) { return; }

                                                window.open(articleEmphasis.url, '_blank');
                                            }}
                                            variation='primary redirect'
                                        >
                                            <p>{t('read-full-article')}</p>

                                            <span>{linkIcon}</span>
                                        </Link>
                                        : <Skeleton
                                            className='skeleton'
                                            height={'1rem'}
                                            width={'20rem'}
                                        />
                                    }
                                </Column>

                                <Row a='start' fit='all'>
                                    {articleEmphasis?.sentiment?.overall_label
                                        ? (<Column fit='width' a='center'>
                                            <h5>{t('sentiment.s')}</h5>

                                            <SentimentIcon variation={articleEmphasis?.sentiment?.overall_label} fill='gradient' />

                                            <h5>{t(articleEmphasis.sentiment.overall_label?.toLowerCase())}</h5>
                                        </Column>)
                                        : <></>
                                    }

                                    <hr />
                                    <Column>
                                        {
                                            articleEmphasis?.topics
                                                ? <>
                                                    <h5>{t('related-topics')}</h5>

                                                    <Column id='topics' flex='wrap' >

                                                        {articleEmphasis.topics.length > 0
                                                            ? articleEmphasis.topics
                                                                .sort((a, b) => b.relevance - a.relevance)
                                                                .map((item, index) => {

                                                                    const { name, relevance } = item;

                                                                    return (
                                                                        <Badge
                                                                            variation={relevance > .70 ? 'relevant' : 'non-relevant'}
                                                                            id={'emphasis-topic-' + index}
                                                                            key={'emphasis-topic-' + index}
                                                                        >
                                                                            <Row fill='width'>
                                                                                <b>{name}</b>

                                                                                <span>{+(relevance * 100).toFixed(2)}%</span>
                                                                            </Row>
                                                                        </Badge>);
                                                                })
                                                            : t('no-related-topics')
                                                        }
                                                    </Column>
                                                </>
                                                : <></>
                                        }
                                    </Column>
                                </Row>
                            </Row>
                        </Column>
                    </Panel>
                </Row >
            </Layout >
        </Page >
    );
};

export default News;
