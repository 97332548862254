/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import i18n from 'locales/i18n';
import { useTranslation } from 'react-i18next';

import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hi';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ru';

import { CaretRightOutlined } from '@ant-design/icons';

import { Checkbox, Select, Radio, Collapse } from 'antd';

import {
    Page, Layout, Loading, MessagePopup, Row, Column, Panel,
    SwitchableIcon, Title, Label, Input, Button, Badge
} from 'components/imports';

import { RefreshSvg } from 'icons/imports';

import { timeframes } from 'constants';

import { getAssetBase } from 'utils/assets-bases';
import { getAssetQuote } from 'utils/assets-quotes';

import { getUserConnectedExchanges } from 'utils/exchanges';
import { getTrendsMarketcaps } from 'utils/trends-marketcaps';

import { getLanguage, getTheme } from 'utils/cookies';

import { atsModesPairsGet } from 'apis/imports';

import './AtsSettings.css';
import { exchanges } from 'ccxt';


const AtsSettings = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'ats-settings';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang] = useState(getLanguage());
    const [appTheme] = useState(getTheme());

    const [popUpDuration,] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [userExchanges,] = useState(getUserConnectedExchanges({}));

    const [negotiationMode, setNegotiationMode] = useState('default');

    const [negotiationsList, setNegotiationsList] = useState([]);
    const [filteredNegotiationsList, setFilteredNegotiationsList] = useState([]);
    const [filteredNegotiationByExchange, setFilteredNegotiationByExchange] = useState(null);

    const [isNewNegotiation, setIsNewNegotiation] = useState(false);
    const [details, setDetails] = useState(null);

    const [searchNegotiation, setSearchNegotiation] = useState('');
    const [loadingNegotiations, setLoadingNegotiations] = useState(true);

    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (loadingNegotiations ? ' spinning' : '')} />;

    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('ats-settings')} - Smart Trade`;
            getNegotiations();
        }
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {
        console.log(negotiationsList);
        setFilteredNegotiationsList(negotiationsList);
    }, [negotiationsList]);


    useEffect(() => {
        let negotiations = [...negotiationsList];

        if (filteredNegotiationByExchange)
            negotiations = negotiations
                .filter((pos) => pos.exchange_slug === filteredNegotiationByExchange);

        if (searchNegotiation !== '')
            negotiations = negotiations
                .filter((neg) => neg.pair.toLowerCase()
                    .includes(searchNegotiation
                        .trim()
                        .toLowerCase()
                    )
                );

        setFilteredNegotiationsList(negotiations);
    }, [negotiationsList, searchNegotiation, filteredNegotiationByExchange]);


    const getNegotiations = async () => {

        if (!isUserAuthenticated) return;

        setLoadingNegotiations(true);

        const { isSuccess, response } = await atsModesPairsGet({ mode: negotiationMode });

        if (isSuccess) {
            let { items } = response;

            items.sort((a, b) => b.pair_id - a.pair_id);

            setNegotiationsList(items);
            if (details == null) setDetails(items[0]);
        }

        setLoadingNegotiations(false);
    };


    const getPresets = () => {

    };


    const handleSearchNegotiation = (event) => {
        event.preventDefault();
        setSearchNegotiation(event.target.value);
    };


    const handleChangeDetailsEnabled = (event) => {
        event.preventDefault();
        let detailsAux = { ...details };
        detailsAux.config.enabled = event.target.checked;
        setDetails(detailsAux);
    };


    const handleChangeDetailsCreateOrder = (event) => {
        event.preventDefault();
        let detailsAux = { ...details };
        detailsAux.config.create_order = event.target.checked;
        setDetails(detailsAux);
    };


    const handleChangeDetailsBaseHasEnough = (event) => {
        event.preventDefault();
        let detailsAux = { ...details };
        detailsAux.config.base.has_enough = event.target.checked;
        setDetails(detailsAux);
    };


    const handleChangeDetailsBaseType = (event) => {
        event.preventDefault();
        let detailsAux = { ...details };
        detailsAux.config.base.type = event.target.value;
        setDetails(detailsAux);
    };


    const handleChangeDetailsBaseAllocation = (event) => {
        event.preventDefault();
        let detailsAux = { ...details };

        let value = event.target.value;

        if (detailsAux.config.base.type === 'percentage')
            value = +(value.replace(['%', ' ', '.', ','], '')) / 100;

        detailsAux.config.base.allocation = value;
        setDetails(detailsAux);
    };


    const handleChangeDetailsQuoteType = (event) => {
        event.preventDefault();
        let detailsAux = { ...details };
        detailsAux.config.quote.type = event.target.value;
        setDetails(detailsAux);
    };


    const handleChangeDetailsQuoteHasEnough = (event) => {
        event.preventDefault();
        let detailsAux = { ...details };
        detailsAux.config.quote.has_enough = event.target.checked;
        setDetails(detailsAux);
    };


    const buildFilterExchangesOptions = () => {
        let options = [{ label: t('all'), value: null }];

        options = options.concat(userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug
        })));

        return options;
    };


    const handleChangeDetailsQuoteAllocation = (event) => {
        event.preventDefault();
        let detailsAux = { ...details };
        let value = event.target.value;

        if (detailsAux.config.quote.type === 'percentage')
            value = (value / 100).toFixed(2);

        detailsAux.config.quote.allocation = value;

        setDetails(detailsAux);
    };


    const formatAssetPercentage = (asset) => {
        let { allocation } = asset;
        allocation *= 100;

        if (allocation > 100) allocation = 100;
        if (allocation < 0) allocation = 0;

        return (allocation).toFixed(0);
    };


    return (
        <Page id={pageId}>
            <Loading id={'overview'} />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='basic'
                showToolbar={true}
                setIsUserAuthenticated={setIsUserAuthenticated}
                showHeader={true}
                scrollable
            >
                <Column fill='all' scroll>
                    <Row fill='width'>
                        <Panel
                            className='negotiations'
                            fill='width'
                            w='8'
                        >
                            <Row
                                fill='width'
                                j='between'
                            >
                                <Title txt={t('negotiation.p')} variation='tertiary' />

                                {/* <Button
                                    onClick={() => { }}
                                    variation='primary'
                                >
                                    {t('new-negotiation')}
                                </Button> */}

                            </Row>

                            <hr />

                            <Row
                                fill='width'
                                j='between'
                                g='1'
                            >
                                <Select
                                    style={{ width: '8rem' }}
                                    options={buildFilterExchangesOptions()}
                                    onChange={(option) => setFilteredNegotiationByExchange(option)}
                                    value={filteredNegotiationByExchange}
                                    placeholder={t('exchange.s')}
                                    variant='borderless'
                                />

                                <Input
                                    id='search-coin-trend'
                                    onChange={handleSearchNegotiation}
                                    placeholder={t('search.coin')}
                                    variation='searchbar'
                                    value={searchNegotiation}
                                />

                                <SwitchableIcon
                                    id='refresh-balances'
                                    onToggle={() => getNegotiations()}
                                    staticImage={refreshIcon}
                                />
                            </Row>

                            <Column fill='all'>
                                <Column
                                    id='negotiations-list'
                                    fill='all'
                                    g='0'
                                    scroll
                                >
                                    {
                                        filteredNegotiationsList
                                            .map((neg, index) => {
                                                const { pair, exchange_slug, pair_id, config } = neg;
                                                const [base, quote] = pair.split('/');

                                                return (
                                                    <>
                                                        <hr />

                                                        <Row
                                                            className={'negotiation-item' + (config.enabled ? '' : ' disabled') + (pair_id === details?.pair_id ? ' selected' : '')}
                                                            fill='width'
                                                            onClick={() => { setDetails(neg); }}
                                                            key={index}
                                                        >
                                                            <Row fill='width'>
                                                                <Label variation='exchange' value={{ exchange: userExchanges.find(exc => exc.slug == exchange_slug) }} />

                                                                <p><b>:</b></p>

                                                                <Label variation='pair-and-name' value={{ pair }} />
                                                            </Row>

                                                            <Column fill='width' w='8'>
                                                                <Row fill='width' j='between'>
                                                                    <p className='side-label'>{t('sell.s')}</p>

                                                                    <Row>
                                                                        <p><b>{config.base.type == 'percentage' ? formatAssetPercentage(config.base) + '%' : config.base.allocation}</b></p>

                                                                        <p className='side-label'>{base}</p>
                                                                    </Row>
                                                                </Row>

                                                                <Row fill='width' j='between'>
                                                                    <p className='side-label'>{t('buy.v')}</p>

                                                                    <Row>
                                                                        <p><b>{config.quote.type == 'percentage' ? formatAssetPercentage(config.quote) + '%' : config.quote.allocation}</b></p>

                                                                        <p className='side-label'>{quote}</p>
                                                                    </Row>
                                                                </Row>
                                                            </Column>
                                                        </Row>
                                                    </>
                                                );
                                            })
                                    }
                                </Column>
                            </Column>
                        </Panel>

                        <Panel
                            id='negotiation-details'
                            a='center'
                            w='16'
                        >
                            <Row fill='width' j='between'>
                                <Title txt={t('negotiation-details')} variation='tertiary' />

                                <Row w='6' g='1'>
                                    {/* <Button
                                        className='fill-width'
                                        onClick={() => { }}
                                        variation='tertiary'
                                    >
                                        {t('cancel')}
                                    </Button>

                                    <Button
                                        className='fill-width'
                                        onClick={() => { }}
                                        variation='secondary'
                                    >
                                        {t('save')}
                                    </Button> */}
                                </Row>
                            </Row>

                            <hr />

                            {details ?
                                <Column
                                    a='center'
                                    fill='all'
                                    scroll
                                >
                                    <Column
                                        fill='all'
                                        g='2'
                                        w='14'
                                    >
                                        <Row
                                            a='start'
                                            fill='width'
                                            j='between'
                                        >
                                            <Column fill='width' w='10'>
                                                <label><b>{t('exchange.s')}</b></label>

                                                {isNewNegotiation ?
                                                    <></>
                                                    : <Select
                                                        disabled={true}
                                                        value={userExchanges.find((exchange) => exchange.slug === details?.exchange_slug).name}
                                                        variant='borderless'
                                                    />
                                                }
                                            </Column>

                                            <Column fill='width' w='10'>
                                                <label><b>{t('settings')}</b></label>

                                                <Checkbox
                                                    checked={details?.config?.enabled}
                                                    onChange={handleChangeDetailsEnabled}
                                                >
                                                    <p>{t('enabled')}</p>
                                                </Checkbox>

                                                <Checkbox
                                                    checked={details?.config?.create_order}
                                                    onChange={handleChangeDetailsCreateOrder}
                                                >
                                                    <p>{t('create-order')}</p>
                                                </Checkbox>
                                            </Column>
                                        </Row>

                                        <Row
                                            a='start'
                                            fill='width'
                                            j='between'
                                        >
                                            {/* Base */}
                                            <Column
                                                a='start'
                                                fill='width'
                                                g='2'
                                                w='10'
                                            >
                                                <Column fill='width' g='1'>
                                                    <label><b>{t('base-asset')}</b></label>

                                                    {isNewNegotiation ?
                                                        <></>
                                                        : <Select
                                                            disabled={true}
                                                            value={details?.base}
                                                            variant='borderless'
                                                        />
                                                    }

                                                    <label><b>{t('allocation.s')}</b></label>

                                                    <Row fill='width' j='center'>
                                                        <Radio.Group
                                                            className='fill-width'
                                                            onChange={handleChangeDetailsBaseType}
                                                            value={details?.config?.base.type}
                                                        >
                                                            <Radio.Button className='w-12' value='balance'>{t('balance.s')}</Radio.Button>
                                                            <Radio.Button className='w-12' value='percentage'>{t('percentage.s')}</Radio.Button>
                                                        </Radio.Group>
                                                    </Row>

                                                    <Checkbox
                                                        checked={details?.config?.base?.has_enough}
                                                        disabled={details?.config?.base?.type == 'percentage'}
                                                        onChange={handleChangeDetailsBaseHasEnough}
                                                    >
                                                        <p>{t('create-order-has-enough')}</p>
                                                    </Checkbox>

                                                    <Row fill='width' j='between'>
                                                        <Row>
                                                            <p className='side-label'>{t('min')}</p>

                                                            <p><b>{details?.config?.base?.allocation?.min || '-'} {details?.base}</b></p>
                                                        </Row>

                                                        <Row>
                                                            <p className='side-label'>{t('max')}</p>

                                                            <p><b>{details?.config?.base?.allocation?.max || '-'} {details?.base}</b></p>
                                                        </Row>
                                                    </Row>

                                                    {details?.config?.base?.type == 'percentage'
                                                        ? <Input
                                                            variation='asset-percentage'
                                                            value={formatAssetPercentage(details?.config?.base)}
                                                            onChange={handleChangeDetailsBaseAllocation}
                                                            placeholder={t('allocation.s')}
                                                            currency={'% ' + details?.base}
                                                        />
                                                        : <Input
                                                            variation='asset-balance'
                                                            value={details?.config?.base.allocation}
                                                            onChange={handleChangeDetailsBaseAllocation}
                                                            placeholder={t('allocation.s')}
                                                            currency={details?.base}
                                                            min={details?.config?.base?.allocation?.min}
                                                            max={details?.config?.base?.allocation?.max}
                                                        />
                                                    }

                                                </Column>
                                            </Column>

                                            {/* Quote */}
                                            <Column
                                                a='start'
                                                fill='width'
                                                g='2'
                                                w='10'
                                            >
                                                <Column fill='width' g='1'>
                                                    <label><b>{t('quote-asset')}</b></label>

                                                    {isNewNegotiation ?
                                                        <></>
                                                        : <Select
                                                            disabled={true}
                                                            value={details?.quote}
                                                            variant='borderless'
                                                        />
                                                    }

                                                    <label><b>{t('allocation.s')}</b></label>

                                                    <Row fill='width' j='center'>
                                                        <Radio.Group
                                                            className='fill-width'
                                                            onChange={handleChangeDetailsQuoteType}
                                                            value={details?.config?.quote.type}
                                                        >
                                                            <Radio.Button className='w-12' value='balance'>{t('balance.s')}</Radio.Button>
                                                            <Radio.Button className='w-12' value='percentage'>{t('percentage.s')}</Radio.Button>
                                                        </Radio.Group>
                                                    </Row>

                                                    <Checkbox
                                                        checked={details?.config?.quote?.has_enough}
                                                        disabled={details?.config?.quote?.type == 'percentage'}
                                                        onChange={handleChangeDetailsBaseHasEnough}
                                                    >
                                                        <p>{t('create-order-has-enough')}</p>
                                                    </Checkbox>

                                                    <Row fill='width' j='between'>
                                                        <Row>
                                                            <p className='side-label'>{t('min')}</p>

                                                            <p><b>{details?.config?.quote?.allocation?.min || '-'} {details?.quote}</b></p>
                                                        </Row>

                                                        <Row>
                                                            <p className='side-label'>{t('max')}</p>

                                                            <p><b>{details?.config?.quote?.allocation?.max || '-'} {details?.quote}</b></p>
                                                        </Row>
                                                    </Row>
                                                    {details?.config?.quote?.type == 'percentage'
                                                        ? <Input
                                                            variation='asset-percentage'
                                                            value={formatAssetPercentage(details?.config?.quote)}
                                                            onChange={handleChangeDetailsBaseAllocation}
                                                            placeholder={t('allocation.s')}
                                                            currency={'%' + details?.quote}
                                                        />
                                                        : <Input
                                                            variation='asset-balance'
                                                            value={details?.config?.quote.allocation}
                                                            onChange={handleChangeDetailsQuoteAllocation}
                                                            placeholder={t('allocation.s')}
                                                            currency={details?.quote}
                                                            min={details?.config?.quote?.allocation?.min}
                                                            max={details?.config?.quote?.allocation?.max}
                                                        />
                                                    }

                                                </Column>
                                            </Column>
                                        </Row>

                                        <Column fill='width'>
                                            <label><b>{t('preset.s')}</b></label>

                                            <Select
                                                value={details?.preset?.name}
                                                variant='borderless'
                                            />

                                            <Row>
                                                <p className='side-label'>{t('timeframe.p')}</p>

                                                {details?.preset?.timeframes.map((timeframe) => (<Badge key={timeframe}>{timeframe}</Badge>))}
                                            </Row>

                                            {/* <Collapse
                                                bordered={false}
                                                defaultActiveKey={['1']}
                                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                items={buildConditionsCollapse()}
                                            /> */}

                                        </Column>
                                    </Column>
                                </Column>
                                : <p>No negotiation to show</p>
                            }
                        </Panel>
                    </Row>

                    {/* <hr />

                    <Row fill='width'>
                        <Panel id='presets-panel' w='8'>
                            <Row fill='width' j='between'>
                                <Title txt={t('preset.p')} variation='tertiary' />

                                <Radio.Group
                                    onChange={handleChangeDetailsQuoteType}
                                    value={details?.config?.quote.type}
                                >
                                    <Radio.Button value='users'>{t('personalized.p')}</Radio.Button>
                                    <Radio.Button value='default'>{t('by-bnx')}</Radio.Button>
                                </Radio.Group>
                            </Row>

                            <hr />

                            <p>Adicionar lista aqui</p>

                        </Panel>

                        <Panel id='preset-details-panel' w='16'>
                            <Row fill='width' j='between'>
                                <Title txt={t('preset-details')} variation='tertiary' />
                            </Row>

                            <hr />

                            <p>Adicionar Detalhes aqui</p>

                        </Panel>
                    </Row> */}
                </Column>
            </Layout>
        </Page>
    );
};

export default AtsSettings;