/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { Column, Row, Label } from 'components/imports';

// Utils
import { currencyFormatter, percentageFormatter } from 'utils/formatters';


const CoinsVariation = (props) => {

    const { selectedCurrency } = props;

    const className = props.className || '';


    return (
        <Column
            className={('list coins-variation ' + className).trim()}
            fill='all'
            id={props.id}
            j='between'
            key={'list-coins-variation-' + props.id}
            scroll
        >
            {
                props.items.map((item) => {

                    var { pnl, price, symbol } = item;

                    pnl /= 100;
                    price = currencyFormatter(price);

                    return (
                        <Row
                            fill='width'
                            key={symbol + '-' + selectedCurrency}
                            j='between'
                        >
                            <Label
                                value={{ symbol }}
                                variation='symbol-and-name'
                            />
                            <Column a='end' g='0'>
                                <p className={`pnl trend-${pnl > 0 ? 'up' : 'down'}-text`}>
                                    <b>{percentageFormatter({ v: pnl })}</b>
                                </p>

                                <Row>
                                    <p className='currency money-value'>{price}</p>
                                    <p className="money-currency">{selectedCurrency}</p>
                                </Row>
                            </Column>
                        </Row>
                    );
                })
            }
        </Column>
    );
};


CoinsVariation.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.array,
    selectedCurrency: PropTypes.string
};


export default CoinsVariation;
