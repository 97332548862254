import React from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Column, ExchangesPill, Row, Label } from 'components/imports';

import { buildExchangesStorageUrl } from 'icons/imports'; // SVG

// Utils
import { currencyFormatter, percentageFormatter } from 'utils/formatters';

import './OrdersTable.css';


const OrdersTable = (props) => {

    const { t } = useTranslation();

    const currency = props.selectedCurrency;


    const handleExpandCoinListItem = (symbol) => {

        $('.table.coins-table .table-item:not(.' + symbol + ') .expandable').addClass('d-none');

        let $expandable = $('.table.coins-table .table-item.' + symbol + ' .expandable');

        $expandable.toggleClass('d-none');
    };


    return (
        <Column id={props.id}
            className={'table coins-table ' + props.variation}
            scroll
            fill='width'
            key={'table-positions-' + props.id}
        >
            <Column
                fill='width'
                g='0'
            >
                {
                    props.items.map((item) => {

                        var { balance, maxPnl, perExchange, symbol, total } = item;

                        var pnl = maxPnl;

                        return (
                            <Column
                                className={'table-item ' + symbol}
                                fill='width'
                                g='0'
                                key={symbol}
                                onClick={() => handleExpandCoinListItem(symbol)}
                            >
                                <hr />

                                <Column
                                    className='card'
                                    fill='width'
                                >
                                    <Row
                                        a='center'
                                        fill='width'
                                        j='between'
                                    >
                                        <Label
                                            value={{ symbol }}
                                            variation='symbol-and-name'
                                        />

                                        <ExchangesPill exchanges={Object.keys(perExchange)} />
                                    </Row>

                                    <Row
                                        fill='width'
                                        j='between'
                                    >
                                        <p>{t('total')}</p>

                                        <Column
                                            a='end'
                                            fit='width'
                                            g='0'
                                        >
                                            {
                                                symbol !== currency
                                                    ?
                                                    <p className={'amount fill-width ' + (props.hideValues ? 'occult' : '')}>
                                                        <b>{currencyFormatter(total || 0)}</b>
                                                    </p>
                                                    : <></>
                                            }

                                            <Row>
                                                <p className={'currency money-value ' + (props.hideValues ? ' occult' : '')}>
                                                    {currencyFormatter(balance || 0)}
                                                </p>

                                                <p className='money-currency'>
                                                    {currency}
                                                </p>
                                            </Row>

                                            {pnl != undefined && pnl != null
                                                ? <p className={'trend-' + (pnl >= 0 ? 'up' : 'down') + '-text'}>
                                                    <b>{percentageFormatter({ v: pnl, multiply: false })}</b>
                                                </p>
                                                : <></>
                                            }
                                        </Column>
                                    </Row>
                                </Column>

                                <Column className='expandable d-none' g='0'>
                                    {
                                        Object.entries(perExchange).map(([exchange, values]) => {

                                            let { balance, pnl, total } = values;

                                            return (
                                                <Row
                                                    a='center'
                                                    fill='width'
                                                    j='between'
                                                    key={exchange}
                                                    p='pri'
                                                >
                                                    <img
                                                        alt={exchange + ' logo'}
                                                        className='asset-icon'
                                                        src={buildExchangesStorageUrl(exchange)}
                                                        title={exchange}
                                                    />

                                                    <Column
                                                        a='end'
                                                        fill='width'
                                                        g='0'
                                                    >
                                                        {
                                                            symbol !== currency
                                                                ? <p className={'amount ' + (props.hideValues ? 'occult' : '')}>
                                                                    <b>{total}</b>
                                                                </p>
                                                                : <></>
                                                        }

                                                        <Row>
                                                            {
                                                                balance
                                                                    ? <p className={'currency money-value ' + (props.hideValues ? ' occult' : '')}>
                                                                        {currencyFormatter(balance)}
                                                                    </p>
                                                                    : <p>--</p>
                                                            }

                                                            <p className='money-currency'>
                                                                {currency}
                                                            </p>
                                                        </Row>

                                                        {pnl != undefined && pnl != null
                                                            ? <p className={'trend-' + (pnl >= 0 ? 'up' : 'down') + '-text'}>
                                                                {percentageFormatter({ v: pnl, multiply: false })}
                                                            </p>
                                                            : <></>
                                                        }
                                                    </Column>
                                                </Row>
                                            );
                                        })
                                    }
                                </Column>
                            </Column>
                        );
                    })
                }
            </Column>
        </Column>
    );
};


OrdersTable.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    deleteExchangeConnection: PropTypes.func,
    exchanges: PropTypes.array,
    hideValues: PropTypes.bool,
    id: PropTypes.string,
    items: PropTypes.array,
    lang: PropTypes.string,
    selectedCurrency: PropTypes.string,
    selectedTimeframe: PropTypes.string,
    onChange: PropTypes.func,
    onItemClick: PropTypes.func,
    variation: PropTypes.string
};


export default OrdersTable;
