import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Column, Row, Label, SwitchableIcon } from 'components/imports';

import { DeleteSvg } from 'icons/imports'; // SVG
// Utils
import { getUserConnectedExchanges } from 'utils/exchanges';


import './UserExchangesList.css';


const UserExchangesList = (props) => {

    const { t } = useTranslation();

    const { className, deleteExchangeConnection, id, items } = props;

    const [userExchanges,] = useState(getUserConnectedExchanges({}));

    const deleteIcon = <DeleteSvg className='icon-svg no' />;


    return (
        <Column id={id}
            className={('list user-exchanges ' + className).trim()}
            fill='width'
            key={'list-user-exchanges-' + id}
            scroll
        >
            {
                items.length > 0 ?
                    items.map((item, index) => {

                        let { slug, credentials } = item;
                        let { apiKey, secret, password } = credentials;

                        return (
                            <Column
                                className='item added'
                                fill='width'
                                j='between'
                                key={'added-exchange-' + index}
                                p='pri'
                            >
                                <Row
                                    fill='width'
                                    j='between'
                                >
                                    <Label
                                        variation='exchange'
                                        value={{ exchange: userExchanges.find(exchange => exchange.slug == slug) }}
                                    />

                                    <SwitchableIcon
                                        id='close-add-exchange-icon'
                                        onToggle={() => deleteExchangeConnection(slug)}
                                        staticImage={deleteIcon}
                                        title='Delete connection'
                                    />
                                </Row>

                                <Row
                                    fit='all'
                                    g='2'
                                    j='between'
                                >
                                    <Column
                                        fit='all'
                                        g='0'
                                    >
                                        <label><b>{t('api-key')}</b></label>
                                        <label>{apiKey}</label>
                                    </Column>

                                    <Column
                                        fit='all'
                                        g='0'
                                    >
                                        <label><b>{t('secret.s')}</b></label>
                                        <label>{secret}</label>
                                    </Column>

                                    {password
                                        ?
                                        <Column
                                            fit='all'
                                            g='0'
                                        >
                                            <label><b>{t('password.s')}</b></label>
                                            <label>{password}</label>
                                        </Column>
                                        : <></>
                                    }
                                </Row>
                            </Column>
                        );

                    }) : <Row className='list-message' fill='width' j='center'>
                        {t('no-exchanges-connected')}
                    </Row>
            }
        </Column>
    );
};


UserExchangesList.propTypes = {
    className: PropTypes.string,
    deleteExchangeConnection: PropTypes.func,
    id: PropTypes.string,
    items: PropTypes.array
};


export default UserExchangesList;
