import React from 'react';
import PropTypes from 'prop-types';

import { Badge, Column, Row, Label } from 'components/imports';

// Utils
import { currencyFormatter, percentageFormatter } from 'utils/formatters';


const TrendScoresList = (props) => {
    
    const { id, items, selectedCurrency } = props;

    const className = props.className || '';


    return (
        <Column
            className={('list ' + className).trim()}
            fill='all'
            id={id}
            key={'list-trend-score-' + id}
            scroll
        >
            {
                items.map((item) => {

                    var { pnl, price, symbol, score } = item;

                    pnl /= 100;
                    price = currencyFormatter(price);

                    return (
                        <Row
                            fill='width'
                            key={symbol + '/' + selectedCurrency}
                            j='between'
                        >
                            <Label
                                value={{ symbol }}
                                variation='symbol-and-name'
                            />

                            <Row>
                                <Column a='end' g='0'>
                                    <p className={`pnl trend-${pnl > 0 ? 'up' : 'down'}-text`}>
                                        <b>{percentageFormatter({ v: pnl })}</b>
                                    </p>

                                    <Row>
                                        <p className='currency money-value'>{price}</p>
                                        <p className='money-currency'>{selectedCurrency}</p>
                                    </Row>
                                </Column>


                                <Badge
                                    id={symbol.toLowerCase() + '-trend-score'}
                                    variation='primary'
                                >
                                    {Math.round(score)}
                                </Badge>
                            </Row>
                        </Row>
                    );
                })
            }
        </Column>
    );

};


TrendScoresList.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.array,
    selectedCurrency: PropTypes.string
};


export default TrendScoresList;
