
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // React Router
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Ant Design
import { Radio, Select } from 'antd';

// Base
import { Row, Column, Panel, Title, Link, SwitchableIcon } from 'components/imports'; // Ajuste o caminho conforme necessário

// Lists
import { OrdersTable, SignalsTable } from 'components/imports';

// Ícones e imagens
import { FilterSvg } from 'icons/imports';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ExchangeConnectionSvg } from 'images/imports';

// APIs
import { signalsUsersMeGet, ordersMeGet } from 'apis/imports';

// Utils
import { RefreshSvg } from 'icons/imports';

// Tradução
import { useTranslation } from 'react-i18next';

import { timeframes } from 'constants';


const TradesPanel = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        appLang,
        selectedTimeframe,
        tickers,
        pageId
    } = props;

    const location = useLocation();

    const [tradeType, setTradeType] = useState('signals');

    const [signalsData, setSignalsData] = useState([]);
    const [loadingSignals, setLoadingSignals] = useState(true);

    const [ordersData, setOrdersData] = useState([]);
    const [loadingOrders, setLoadingOrders] = useState(true);

    const [expandFilters, setExpandFilters] = useState(false);

    const [exchangesFilter, setExchangesFilter] = useState([]);
    const [pairsFilter, setPairsFilter] = useState([]);
    const [timeframesFilter, setTimeframesFilter] = useState([]);
    const [sideFilter, setSideFilter] = useState(null);

    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + ((tradeType === 'signals' && loadingSignals) ||
        (tradeType === 'orders' && loadingOrders) ? ' spinning' : '')} />;

    const filterIcon = <FilterSvg className={'icon-svg'} />;


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            getTrades();
        }
    }, [location.pathname]);


    useEffect(() => {
        patchSignals();
    }, [tickers]);


    useEffect(() => {
        getTrades();
    }, [exchangesFilter, pairsFilter, timeframesFilter, sideFilter]);


    const getSignals = async () => {

        setLoadingSignals(true);

        try {
            const result = await signalsUsersMeGet({
                exchanges: exchangesFilter,
                pairs: pairsFilter,
                timeframes: timeframesFilter,
                side: sideFilter,
                limit: 10
            });

            if (result.isSuccess) {
                const response = result.response.items.map((item, index) => ({
                    isAtsOrder: item.is_ats_order,
                    key: index,
                    timestamp: item.timestamp,
                    exchangeSlug: item.exchange_slug,
                    pair: item.pair,
                    side: item.side,
                    price: item.price,
                    timeframe: item.timeframe,
                    pnl: item.pnl
                }));

                setSignalsData(response);
            } else {
                props.setPopUpLevel('error');
                props.setPopUpText(t('error.fetch-signals'));
            }
        } catch (error) {
            props.setPopUpLevel('error');
            props.setPopUpText(t('network-error'));
        } finally {
            setLoadingSignals(false);
        }
    };


    const getOrders = async () => {

        setLoadingOrders(true);

        try {
            const result = await ordersMeGet({
                exchanges: exchangesFilter,
                pairs: pairsFilter,
                side: sideFilter,
                limit: 10
            });


            if (result.isSuccess) {
                const response = result.response.items.map((item, index) => {

                    let { timestamp, pair, side, price, pnl, status, amount, filled } = item;

                    return {
                        key: index,
                        isAtsOrder: item.is_ats_order,
                        orderId: item.order_id,
                        exchangeSlug: item.exchange_slug,
                        timestamp,
                        pair,
                        side,
                        price,
                        pnl,
                        status,
                        amount,
                        filled
                    };
                });

                setOrdersData(response);
            } else {
                if (props.setPopUpLevel) props.setPopUpLevel('error');
                if (props.setPopUpText) props.setPopUpText(t('error.fetch-orders'));
            }
        } catch (error) {
            if (props.setPopUpLevel) props.setPopUpLevel('error');
            if (props.setPopUpText) props.setPopUpText(t('network-error'));
        } finally {
            setLoadingOrders(false);
        }
    };


    const patchSignals = () => {
        const signalsAux = [...signalsData];
        const signalsByPair = {};

        // Agrupar sinais por par
        signalsAux.forEach(signal => {
            const { pair } = signal;
            if (!signalsByPair[pair]) {
                signalsByPair[pair] = [];
            }
            signalsByPair[pair].push(signal);
        });

        // Encontrar a operação de compra mais recente não finalizada para cada par
        const openPositions = {};

        Object.keys(signalsByPair).forEach(pair => {
            const signals = signalsByPair[pair];

            // Ordenar sinais por data descendente
            signals.sort((a, b) => a.timestamp - b.timestamp);

            let isOpen = false;
            let potentialOpenBuy = null;

            for (let i = 0; i < signals.length; i++) {
                const signal = signals[i];

                if (signal.side === 'buy') {
                    if (!isOpen) {
                        // Possível operação de compra aberta
                        potentialOpenBuy = signal;
                        isOpen = true;
                    }
                } else if (signal.side === 'sell') {
                    if (isOpen) {
                        // A operação de compra foi fechada por uma venda
                        isOpen = false;
                        potentialOpenBuy = null;
                    }
                }
            }

            // Se ainda houver uma operação de compra aberta, adicioná-la
            if (isOpen && potentialOpenBuy) {
                openPositions[pair] = potentialOpenBuy;
            }
        });

        // Calcular o PnL para as posições abertas
        const updatedSignalsData = signalsAux.map(signal => {
            const { exchangeSlug, side, pair } = signal;

            if (side === 'buy' && openPositions[pair] && signal === openPositions[pair]) {

                if (tickers && exchangeSlug in tickers && pair in tickers[exchangeSlug]) {
                    const ticker = tickers[exchangeSlug][pair];

                    if (ticker && ticker.pnls && ticker.pnls[selectedTimeframe]) {
                        // Calcular o PnL com base no preço atual e no preço de compra
                        const currentPrice = ticker.price;
                        const pnl = (currentPrice - signal.price) / signal.price;

                        signal.pnl = pnl;
                    }
                }
            }
            return signal;
        });

        setSignalsData(updatedSignalsData);
    };



    const getTrades = () => {
        if (props.isUserAuthenticated) {
            getSignals();
            getOrders();
        }
    };


    const buildExchangesFilter = () => {
        return props.exchanges.reduce((options, exchange) => {
            options.push({
                label: exchange.name,
                value: exchange.slug
            });

            return options;
        }, []);
    };


    const buildPairsFilter = () => {
        return [
            { symbol: 'SUI/USDT', name: 'Sui/Tether USDt' },
            { symbol: 'STX/USDT', name: 'Stacks/Tether USDt' },
            { symbol: 'JUP/USDT', name: 'Jupiter/Tether USDt' },
            { symbol: 'WLD/USDT', name: 'WLD/Tether USDt' },
        ].reduce((options, pair) => {
            options.push({
                label: pair.symbol,
                value: pair.symbol
            });

            return options;
        }, []);
    };


    const buildTimeframesFilter = () => {
        return timeframes.reduce((options, timeframe) => {
            options.push({
                label: timeframe,
                value: timeframe
            });

            return options;
        }, []);
    };


    const buildSideFilter = () => {
        return [
            { label: t('all-sides'), value: 'all' },
            { label: t('buy.v'), value: 'buy' },
            { label: t('sell.s'), value: 'sell' },
        ];
    };


    return (
        <Panel id='last-trades'
            fill='all'
            key='signals'
            g='0'
        >
            <Row
                fill='width'
                j='between'
            >
                <Column g='0'>
                    <Title variation='tertiary' txt={t('last-trades')} />

                    <Link
                        id='go-to-trades-page'
                        onClick={() => {
                            let result = confirm(t('confirm-redirect-to-trades'));

                            if (!result) { return; }
                            navigate('/trades');
                        }}
                        variation='primary redirect'
                    >
                        <p>{t('see-more-trades')}</p>
                    </Link>
                </Column>

                <Row>
                    <Radio.Group value={tradeType} onChange={e => setTradeType(e.target.value)}>
                        <Radio.Button value='signals'>{t('signal.p')}</Radio.Button>
                        <Radio.Button value='orders'>{t('order.p')}</Radio.Button>
                    </Radio.Group>

                    <SwitchableIcon
                        id='expand-filters'
                        onToggle={() => setExpandFilters(!expandFilters)}
                        staticImage={filterIcon}
                    />

                    <SwitchableIcon
                        id='refresh-trades'
                        onToggle={getTrades}
                        staticImage={refreshIcon}
                    />
                </Row>
            </Row>

            <Row fill='width' style={{ display: expandFilters ? 'flex' : 'none' }}>
                <Select
                    mode='multiple'
                    maxCount={3}
                    placeholder={t('exchange.p')}
                    variant='borderless'
                    style={{ flex: 1 }}
                    options={buildExchangesFilter()}
                    onChange={(value) => setExchangesFilter(value)}
                />

                <Select
                    mode='tags'
                    maxCount={3}
                    placeholder={t('pair.p')}
                    variant='borderless'
                    style={{ flex: 1 }}
                    options={buildPairsFilter()}
                    onChange={(value) => setPairsFilter(value)}
                />

                {tradeType == 'signals' && (
                    <Select
                        mode='multiple'
                        maxCount={3}
                        placeholder={t('timeframe.p')}
                        variant='borderless'
                        style={{ flex: 1 }}
                        options={buildTimeframesFilter()}
                        onChange={(value) => setTimeframesFilter(value)}
                    />
                )}

                <Select
                    placeholder={t('side.s')}
                    variant='borderless'
                    defaultValue={['all']}
                    style={{ flex: 1 }}
                    options={buildSideFilter()}
                    onChange={(value) => setSideFilter(value == 'all' ? null : value)}
                />
            </Row>

            <hr />

            {
                (tradeType === 'signals' && loadingSignals) ||
                    (tradeType === 'orders' && loadingOrders) ||
                    !props.isUserAuthenticated
                    ? (<Row
                        fill='width'
                        j='between'
                    >
                        <Skeleton
                            className='skeleton'
                            count={3}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'10rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={3}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'10rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={3}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={3}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={3}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                    </Row>)
                    : (signalsData.length === 0
                        ? (<Column
                            a='center'
                            fill='all'
                            j='center'
                        >
                            <p>{t('no-results')}</p>

                            {/* <Column
                                a='center'
                                fit='all'
                                j='center'
                            >
                                <img className='empty-img' src={ExchangeConnectionSvg} />

                                <p>{t('message-signals-empty')}</p>
                            </Column> */}
                        </Column>)
                        : (tradeType === 'signals' && <SignalsTable id='signals'
                            exchanges={props.exchanges}
                            items={signalsData}
                            lang={appLang}
                        />) || (tradeType === 'orders' && <OrdersTable id='orders'
                            exchanges={props.exchanges}
                            items={ordersData}
                            lang={appLang}
                            variation='orders'
                        />)
                    )
            }
        </Panel >
    );
};


TradesPanel.propTypes = {
    appLang: PropTypes.string,
    exchanges: PropTypes.array,
    isUserAuthenticated: PropTypes.bool,
    pageId: PropTypes.string,
    selectedExchange: PropTypes.string,
    selectedTimeframe: PropTypes.string,
    tickers: PropTypes.object,
    setPopUpLevel: PropTypes.func,
    setPopUpText: PropTypes.func
};


export default TradesPanel;
