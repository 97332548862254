import $ from 'jquery';

import { appEnv, userService } from 'constants';


export const fetchAndSaveInStorageGlobalAssets = () => {
    $.get(`${userService.hosts[appEnv]}/assets`).then(response => {
        localStorage.setItem('assets', JSON.stringify(response));
    });
};


export const getGlobalAssets = () => {

    let assets = localStorage.getItem('assets');

    if (!assets || assets == 'undefined') return {};
    return JSON.parse(assets);
};


export const getGlobalAssetsBases = ({ asAnObject = false }) => {

    let { bases } = getGlobalAssets() || { bases: {} };

    if (!bases) bases = {};
    if (asAnObject) return bases;
    return Object.values(bases);
};


export const getGlobalAssetsCurrencies = ({asAnObject = false}) => {

    let { currencies } = getGlobalAssets() || { currencies: {} };

    if (!currencies) currencies = {};
    if (asAnObject) return currencies;
    return Object.values(currencies);
};


export const getGlobalAssetsQuotes = ({asAnObject = false}) => {

    let { quotes } = getGlobalAssets() || { quotes: {} };

    if (!quotes) quotes = {};
    if (asAnObject) return quotes;
    return Object.values(quotes);
};