/* eslint-disable react/prop-types */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */

// React and core libraries
import React, { useState, useEffect } from 'react';

import i18n from 'locales/i18n';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hi';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ru';

// Cookies, Tour, and i18n
import { useTour } from '@reactour/tour';
import { stepWallet } from 'steps';

// Custom Components
import {
    Loading,
    Column, Layout, Page, Row,
    MessagePopup
} from 'components/imports';

// Utilities
import {
    getSelectedCurrency, getSelectedExchange, getSelectedTimeframe, getLanguage, getTop100Filter,
    getTheme
} from 'utils/cookies';

import { getUserConnectedExchanges } from 'utils/exchanges';

import { getAssetBase } from 'utils/assets-bases';

import { getTrendsMarketcaps } from 'utils/trends-marketcaps';

import { BalancesPanel } from 'components/imports';

import PortfolioPanel from './components/PortfolioPanel';

import usePriceWatcher from 'hooks/usePriceWatcher';

// Styles
import './Wallet.css';



const Wallet = () => {

    const { t } = useTranslation();

    const pageId = 'wallet';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang,] = useState(getLanguage());
    const [appTheme,] = useState(getTheme());
    const [appSelectedExchange, setAppSelectedExchange] = useState(getSelectedExchange());
    const [appSelectedCurrency, setAppSelectedCurrency] = useState(getSelectedCurrency());
    const [appSelectedTimeframe, setAppSelectedTimeframe] = useState(getSelectedTimeframe());
    const [appTop100Filter, setAppTop100Filter] = useState(getTop100Filter());

    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');
    const [popUpDuration, setPopUpDuration] = useState(3000);

    const { setIsOpen, setSteps, setCurrentStep } = useTour();

    const [userExchanges, setUserExchanges] = useState(getUserConnectedExchanges({}));

    const [tickerTapeCoins, setTickerTapeCoins] = useState([]);
    const [tickers, setTickers] = useState({});

    const [loadingTickers, setLoadingTickers] = useState(true);


    const connectWs = false;

    usePriceWatcher(isUserAuthenticated, userExchanges, appSelectedCurrency, appSelectedTimeframe, appTop100Filter, setTickers, connectWs);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('wallet')} - Smart Trade`;

            $(document).scrollTop = 0;
            $('html, body').animate({ scrollTop: 0 }, 1);

            let $body = document.body.classList;
            $body.remove(...$body);
            $body.add('wallet-page');

            localStorage.setItem('lastVisitedPage', location.pathname);
        }
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {

        if (Object.keys(tickers).length === 0) return;

        setLoadingTickers(false);

        if (!(appSelectedExchange in tickers)) return;

        let trendsMarketcaps = {};

        if (appTop100Filter) {
            trendsMarketcaps = getTrendsMarketcaps({ asObject: true });
        }

        let tickerList = Object.entries(tickers[appSelectedExchange]).reduce((tickers, [pair, ticker]) => {
            const [base, _] = pair.split('/');

            if (base.includes('USD')) return tickers;

            ticker.symbol = base;

            if (appTop100Filter && trendsMarketcaps) {
                let baseAsset = getAssetBase({ symbol: base });
                if (!(baseAsset.slug in trendsMarketcaps)) return tickers;
            }

            ticker.pnl = ticker.pnls[appSelectedTimeframe][1];

            tickers.push(ticker);

            return tickers;
        }, []);

        if (!tickerList.length) return;

        let exchangeTickers = tickers[appSelectedExchange];

        let updateTickerTapeCoins = tickerTapeCoins.map((ticker) => {

            if (!(ticker.pair in exchangeTickers)) return ticker;

            ticker.price = exchangeTickers[ticker.pair].price;
            ticker.pnl = exchangeTickers[ticker.pair].pnls[appSelectedTimeframe][1];

            return ticker;
        });

        setTickerTapeCoins(updateTickerTapeCoins);

    }, [tickers]);


    return (
        <Page id={pageId} >
            <Loading id={'wallet-overlay'} />

            <MessagePopup
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                setIsUserAuthenticated={setIsUserAuthenticated}
                setTop100Filter={setAppTop100Filter}
                setSelectedExchange={setAppSelectedExchange}
                setSelectedCurrency={setAppSelectedCurrency}
                setSelectedTimeframe={setAppSelectedTimeframe}
                setUserExchanges={setUserExchanges}
                showToolbar={true}
                showHeader={true}
            >
                <Row
                    a='unset'
                    className='main-row'
                    fill='all'
                >
                    {/* First Column */}
                    <Column w='6'>
                        {/* Wallet */}
                        <BalancesPanel
                            pageId={pageId}
                            isUserAuthenticated={isUserAuthenticated}
                            tickers={tickers}
                            exchanges={userExchanges}
                            selectedExchange={appSelectedExchange}
                            selectedCurrency={appSelectedCurrency}
                            selectedTimeframe={appSelectedTimeframe}
                            showLink={false}
                        />
                    </Column>

                    {/* Second Column */}
                    <Column className='w-20' fill='all'>
                        <PortfolioPanel 
                            selectedExchange={appSelectedExchange}
                            selectedCurrency={appSelectedCurrency}
                            selectedTimeframe={appSelectedTimeframe}
                            tickers={tickers}
                        />
                    </Column>
                </Row>
            </Layout>
        </Page >
    );
};

export default Wallet;
