/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { useNavigate } from 'react-router-dom';
// React-i18next
import { useTranslation } from 'react-i18next';

import { Select, Tooltip } from 'antd';

import { Line } from '@antv/g2plot';

import moment from 'moment';

import Skeleton from 'react-loading-skeleton';

import { InfoSvg, RefreshSvg, TrendUpSvg, TrendDownSvg } from 'icons/imports'; // Helpers

import { Column, Link, Panel, Row, Title, SwitchableIcon, Button } from 'components/imports';

// API Endpoints
import { pnlGet } from 'apis/imports';


const AtsOverviewPanel = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { pageId, isUserAuthenticated, showLink } = props;

    const [pnl, setPnl] = useState({});
    const [loadingPnl, setLoadingPnl] = useState(true);

    const [pnlPeriod, setPnlPeriod] = useState('week');
    const [showWinningInfo, setShowWinningInfo] = useState(false);
    const [showLosingInfo, setShowLosingInfo] = useState(false);

    const [interruptAtsButtonState, setInterruptAtsButtonState] = useState('enabled');

    const infoIcon = <InfoSvg className='icon-svg' />;
    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (loadingPnl ? ' spinning' : '')} />;
    const trendUpIcon = <TrendUpSvg className={'icon-svg'} />;
    const trendDownIcon = <TrendDownSvg className={'icon-svg'} />;


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            getPnl();
        }
    }, [location.pathname]);


    useEffect(() => {
        let pnlLinePlot;

        const renderPlot = () => {
            $('#pnl-area-container').empty();

            if (pnlPeriod in pnl && pnl[pnlPeriod].length > 0 && !loadingPnl && isUserAuthenticated) {
                const data = pnl[pnlPeriod].reduce((acc, item) => {
                    acc.push({ date: item.date, value: item.target, type: 'target' });
                    acc.push({ date: item.date, value: item.pnl, type: 'pnl' });
                    return acc;
                }, []);
                const lastData = data[data.length - 1];

                pnlLinePlot = new Line('pnl-area-container', {
                    data,
                    xField: 'date',
                    yField: 'value',
                    seriesField: 'type',
                    padding: [0, 0, 0, -50],
                    autoFit: true,
                    meta: {
                        value: {
                            alias: 'Percentage',
                            formatter: (val) => `${val}%`,
                        },
                        date: {
                            alias: 'Date',
                            formatter: (val) => moment.unix(parseInt(val, 10)).format('DD/MM'),
                        },
                    },
                    xAxis: {
                        label: null,
                        grid: null,
                        line: null,
                    },
                    yAxis: {
                        label: null,
                        grid: null,
                        min: Math.min(...data.map((d) => d.value)),
                        max: Math.max(...data.map((d) => d.value)),
                    },
                    tooltip: {
                        formatter: (datum) => {
                            return {
                                name: datum.type === 'target' ? t('target.s') : t('pnl'),
                                value: `${datum.value}%`,
                            };
                        },
                    },
                    lineStyle: ({ type }) => {
                        return type === 'target'
                            ? { lineDash: [4, 4], lineWidth: 2 }
                            : { lineWidth: 2 };
                    },
                    annotations: [
                        {
                            type: 'text',
                            position: [lastData.date, lastData.value],
                            content: `${lastData.value}%`,
                            style: {
                                fill: '#58D4A3',
                                fontSize: 14,
                                fontWeight: 'bold',
                                textAlign: 'center',
                            },
                            offsetX: 25,
                        },
                    ],
                    legend: false,
                });

                pnlLinePlot.render();
            }
        };

        // Initial render
        renderPlot();

        // Resize listener
        const handleResize = () => {
            if (pnlLinePlot) pnlLinePlot.destroy();
            renderPlot();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup
            if (pnlLinePlot) pnlLinePlot.destroy();
            window.removeEventListener('resize', handleResize);
        };
    }, [pnl, pnlPeriod, loadingPnl, isUserAuthenticated]);


    const interruptAts = async () => {
        setInterruptAtsButtonState('loading');

        const dialog = confirm(t('ask-confirm-interrupt-ats'));

        if (!dialog) {
            setInterruptAtsButtonState('enabled');
            return;
        }

        setTimeout(() => {
            setInterruptAtsButtonState('success');
        }, 2000);
    };


    const getPnl = async () => {
        setLoadingPnl(true);
        // const result = await pnlGet({ limit: 10 });
        setLoadingPnl(false);

        setPnl({
            all: [
                { date: 1735786800, pnl: 1.5, target: 2.0 },
                { date: 1735873200, pnl: -0.7, target: 2.0 },
                { date: 1735959600, pnl: 2.1, target: 2.0 },
                { date: 1736046000, pnl: 0.5, target: 2.0 },
                { date: 1736132400, pnl: -1.2, target: 2.0 },
                { date: 1736218800, pnl: 0.0, target: 2.0 },
                { date: 1736305200, pnl: 1.8, target: 2.0 },
                { date: 1736391600, pnl: -0.3, target: 2.0 },
            ],
            year: [
                { date: 1735786800, pnl: 1.5, target: 2.0 },
                { date: 1735873200, pnl: -0.7, target: 2.0 },
                { date: 1735959600, pnl: 2.1, target: 2.0 },
                { date: 1736046000, pnl: 0.5, target: 2.0 },
                { date: 1736132400, pnl: -1.2, target: 2.0 },
                { date: 1736218800, pnl: 0.0, target: 2.0 },
                { date: 1736305200, pnl: 1.8, target: 2.0 },
                { date: 1736391600, pnl: -0.3, target: 2.0 },
            ],
            month: [
                { date: 1735786800, pnl: 1.5, target: 2.0 },
                { date: 1735873200, pnl: -0.7, target: 2.0 },
                { date: 1735959600, pnl: 2.1, target: 2.0 },
                { date: 1736046000, pnl: 0.5, target: 2.0 },
                { date: 1736132400, pnl: -1.2, target: 2.0 },
                { date: 1736218800, pnl: 0.0, target: 2.0 },
                { date: 1736305200, pnl: 1.8, target: 2.0 },
                { date: 1736391600, pnl: -0.3, target: 2.0 },
            ],
            week: [
                { date: 1735786800, pnl: 1.5, target: 2.0 },
                { date: 1735873200, pnl: -0.7, target: 2.0 },
                { date: 1735959600, pnl: 2.1, target: 3.0 },
                { date: 1736046000, pnl: 0.5, target: 3.0 },
                { date: 1736132400, pnl: -1.2, target: 5.0 },
                { date: 1736218800, pnl: 0.0, target: 5.0 },
                { date: 1736305200, pnl: 1.8, target: 5.0 },
                { date: 1736391600, pnl: 4, target: 5.0 },
            ]
        });
    };


    const buildPnlPeriodsFilter = () => {
        return [
            { label: t('last.day.p').replace('{}', '7'), value: 'week' },
            { label: t('last.day.p').replace('{}', '30'), value: 'month' },
            { label: t('all-time'), value: 'all' },
        ];
    };


    return (
        <Panel
            id='ats-panel'
            fill='all'
            g='0'
        >
            <Row fill='width' j='between'>
                <Row>
                    <Column g='0'>
                        <Title variation='tertiary' txt={t('ats-overview')} />

                        {showLink &&
                            <Link
                                id='go-to-news-page'
                                onClick={() => {
                                    const result = confirm(t('confirm-redirect-to-ats-reports'));
                                    if (!result) return;
                                    navigate('/ats-reports');
                                }}
                                variation='primary redirect'
                            >
                                <p>{t('ats-reports-link')}</p>
                            </Link>
                        }
                    </Column>
                </Row>

                <Row>
                    <Button
                        id='interrupt-ats'
                        onClick={() => interruptAts()}
                        variation='quaternary'
                        state={interruptAtsButtonState}
                    >
                        <p>{t('interrupt-ats')}</p>
                    </Button>

                    <SwitchableIcon
                        id='refresh-pnl'
                        onToggle={getPnl}
                        staticImage={refreshIcon}
                    />
                </Row>
            </Row>

            <hr />

            {loadingPnl || !isUserAuthenticated ? (
                <div id='last-articles-skeleton'>
                    <hr />
                    <Column p='pri'>
                        <Skeleton height='5rem' width='100%' />

                        <Row>
                            <Skeleton height='1.5rem' width='1.5rem' />
                            <Skeleton height='1.25rem' width='5rem' />
                        </Row>

                        <Column g='0'>
                            <Skeleton height='1.25rem' width='100%' />
                            <Skeleton height='1.25rem' width='10rem' />
                        </Column>
                    </Column>
                </div>
            ) : (
                <Column fill='all' g='0'>
                    <Row fill='width' j='between'>
                        <Row w='6'>
                            <Select
                                variant='borderless'
                                defaultValue={pnlPeriod}
                                style={{ flex: 1 }}
                                options={buildPnlPeriodsFilter()}
                                onChange={(value) => setPnlPeriod(value)}
                            />
                        </Row>

                        <Row p='pri' fit='width'>
                            <Row g='1'>
                                {trendUpIcon}

                                <Title variation='tertiary' txt='24' />

                                <Tooltip
                                    open={showWinningInfo}
                                    title={<b>{t('winning-trades-on-period')}</b>}
                                >
                                    <Row id='icon-container' onClick={() => setShowWinningInfo(!showWinningInfo)}>
                                        {infoIcon}
                                    </Row>
                                </Tooltip>
                            </Row>

                            <Row g='1'>
                                {trendDownIcon}

                                <Title variation='tertiary' txt='6' />

                                <Tooltip
                                    open={showLosingInfo}
                                    title={<b>{t('losing-trades-on-period')}</b>}
                                >
                                    <Row id='icon-container' onClick={() => setShowLosingInfo(!showLosingInfo)}>
                                        {infoIcon}
                                    </Row>
                                </Tooltip>
                            </Row>
                        </Row>
                    </Row>

                    <div id='pnl-area-container' className='fill-width' style={{ height: '70%' }} />
                </Column>
            )
            }
        </Panel >
    );
};


AtsOverviewPanel.propTypes = {
    appLang: PropTypes.string,
    isUserAuthenticated: PropTypes.bool,
    pageId: PropTypes.string,
    showLink: PropTypes.bool
};


export default AtsOverviewPanel;
