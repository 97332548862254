import React from 'react';
import PropTypes from 'prop-types';

import { Column, Row } from 'components/imports';

import { buildCurrencyStorageUrl, buildExchangesStorageUrl, } from 'icons/imports';

import { getAssetBase } from 'utils/assets-bases';
import { getAssetQuote } from 'utils/assets-quotes';

import './Label.css';


const Label = (props) => {

    const { exchange, symbol, pair } = props.value;

    let base, quote;

    if (pair) {
        let [baseSymbol, quoteSymbol] = pair.split('/');
        base = getAssetBase({ symbol: baseSymbol });
        quote = getAssetQuote({ symbol: quoteSymbol });
    }
    else if (symbol) {
        base = getAssetBase({ symbol });
    }

    switch (props.variation) {
        case 'exchange':
            return (
                <Row
                    className={('label ' + props.variation + ' ' + props.className).trim()}
                >
                    {exchange?.slug &&
                        <img
                            className='coin-icon'
                            src={buildExchangesStorageUrl(exchange.slug)}
                        />
                    }

                    <p>
                        <b>{exchange?.name || '-'}</b>
                    </p>
                </Row>
            );
        case 'symbol':
            return (
                <Row
                    className={('label coin-info ' + props.variation + ' ' + props.className).trim()}
                    fill='width'
                >
                    <img
                        className='coin-icon'
                        src={buildCurrencyStorageUrl(base.slug)}
                    />

                    <p className='coin-symbol'>
                        <b>{base.symbol}</b>
                    </p>
                </Row>
            );
        case 'symbol-name':
            return (
                <Row
                    className={('label coin-info ' + props.variation + ' ' + props.className).trim()}
                    fill='width'
                >
                    <img
                        className='coin-icon'
                        src={buildCurrencyStorageUrl(base.slug)}
                    />

                    <p>
                        {base.name}
                    </p>
                </Row>
            );
        case 'symbol-and-name':
            return (
                <Row
                    className={('label coin-info ' + props.variation + ' ' + props.className).trim()}
                    fill='width'
                >
                    <img className='coin-icon' src={buildCurrencyStorageUrl(base.slug) } />

                    <Column g='0'>
                        <p className='coin-symbol'><b>{base.symbol}</b></p>
                        <p className='coin-name'>{base.name}</p>
                    </Column>
                </Row>
            );
        case 'pair':
            return (
                <Row
                    className={('label coin-info ' + props.variation + ' ' + props.className).trim()}
                    fill='width'
                >
                    <img className='coin-icon base-icon' src={buildCurrencyStorageUrl(base.slug)} />

                    <img className='coin-icon quote-icon' src={buildCurrencyStorageUrl(quote.slug)} />

                    <p className='coin-symbol'><b>{base.symbol} / {quote.symbol}</b></p>
                </Row>
            );
        case 'pair-name':
            return (
                <Row
                    className={('label coin-info ' + props.variation + ' ' + props.className).trim()}
                    fill='width'
                >
                    <img className='coin-icon base-icon' src={buildCurrencyStorageUrl(base.slug)} />

                    <img className='coin-icon quote-icon' src={buildCurrencyStorageUrl(quote.slug)} />

                    <p>{base.name} / {quote.name}</p>
                </Row>
            );
        case 'pair-and-name':
            return (
                <Row
                    className={('label coin-info ' + props.variation + ' ' + props.className).trim()}
                    fill='width'
                >
                    <img className='coin-icon base-icon' src={buildCurrencyStorageUrl(base.slug)} />

                    <img className='coin-icon quote-icon' src={buildCurrencyStorageUrl(quote.slug)} />

                    <Column g='0'>
                        <p className='coin-symbol'><b>{base.symbol} / {quote.symbol}</b></p>

                        <p className='coin-name'>{base.name} / {quote.name}</p>
                    </Column>
                </Row>
            );
    }
};

Label.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    variation: PropTypes.string,
    children: PropTypes.node,
    value: PropTypes.object
};

export default Label;