/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Badge, Column, Date, Row, Label } from 'components/imports';

// Utils
import { currencyFormatter, percentageFormatter } from 'utils/formatters';

import './SignalsTable.css';


const SignalsTable = (props) => {

    const { t } = useTranslation();


    return (
        <Column
            className='table signals'
            fill='all'
            id={props.id}
            key={'table-signals-' + props.id}
        >
            { // No results message
                props.items.length === 0 &&
                <Row fill='width' j='center'><b>{t('no-results')}</b></Row>
            }

            { // Render Table
                props.items.length > 0 &&
                <>
                    <Row id='signals-table-header'
                        fill='width'
                        j='between'
                    >
                        <label className='col-0'><b>{t('time')}</b></label>
                        <label className='col-1'><b>{t('exchange.s')}</b></label>
                        <label className='col-2'><b>{t('pair.s')}</b></label>
                        <label className='col-3' ><b>{t('price.s')}</b></label>
                        <label className='col-4' ><b>{t('side.s')}</b></label>
                        <label className='col-5' ><b>{t('timeframe.s')}</b></label>
                        <label className='col-6' ><b>{t('pnl')}</b></label>
                    </Row>

                    <Column fill='all' scroll>
                        {
                            props.items.map((signal, index) => {

                                let { exchangeSlug, pnl, pair, price, side, timeframe, timestamp } = signal;

                                const exchange = props.exchanges.find(exchange => exchange.slug === exchangeSlug);

                                return (
                                    <Row
                                        className='table-item'
                                        fill='width'
                                        j='between'
                                        key={'signal-' + index}
                                    >
                                        <Date className='col-0' timestamp={timestamp} />

                                        {exchange ?
                                            <Label
                                                className='col-1'
                                                value={{ exchange }}
                                                variation='exchange'
                                            />
                                            : <p>{exchangeSlug}</p>
                                        }

                                        <Label
                                            className='col-2'
                                            value={{ pair }}
                                            variation='pair-and-name'
                                        />

                                        <p className='col-3'>
                                            <b>{currencyFormatter(price)}</b>
                                        </p>

                                        <Badge
                                            className='col-4'
                                            variation={side}
                                        >
                                            {side.toUpperCase()}
                                        </Badge>

                                        <p className='col-5'>
                                            <b>{timeframe}</b>
                                        </p>

                                        <p className={'col-6 pnl trend-' + (pnl ? (pnl > 0 ? 'up' : 'down') : 'neutral') + '-text'}>
                                            <b>{pnl != undefined && pnl != null ? percentageFormatter({ v: pnl }) : '-'}</b>
                                        </p>
                                    </Row>
                                );
                            })
                        }
                    </Column>
                </>
            }
        </Column>
    );


};


SignalsTable.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    deleteExchangeConnection: PropTypes.func,
    exchanges: PropTypes.array,
    hideValues: PropTypes.bool,
    id: PropTypes.string,
    items: PropTypes.array,
    lang: PropTypes.string,
    selectedCurrency: PropTypes.string,
    selectedTimeframe: PropTypes.string,
    onChange: PropTypes.func,
    onItemClick: PropTypes.func,
    variation: PropTypes.string
};


export default SignalsTable;
