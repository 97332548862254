/* eslint-disable @typescript-eslint/no-unused-vars */

import { trendsMarketcapsGet } from 'apis/imports';


export const getTrendsMarketcaps = ({ asObject = false }) => {

    let marketcaps = localStorage.getItem('marketcaps');

    if (!marketcaps || marketcaps == 'undefined') {
        trendsMarketcapsGet().then(result => {
            let { response } = result;
            localStorage.setItem('marketcaps', JSON.stringify(response.items));
            if (asObject) return marketcaps;
            return Object.values(marketcaps || {});
        });
    }
    else marketcaps = JSON.parse(marketcaps);

    if (asObject) return marketcaps;
    return Object.values(marketcaps || {});
};
