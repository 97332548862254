/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Ant Design Components
import { Modal, DatePicker, Checkbox } from 'antd';

import { timeframes } from 'constants';

// Custom Components
import { Button, Row, Title } from 'components/imports';
import { Select, Radio } from 'antd';

import { atsReportsExportsMePost } from 'apis/imports';

import { CloseSvg, FileCsvSvg, FileXlsxSvg, FileJsonSvg, FileXmlSvg } from 'icons/imports';

import { getUserConnectedExchanges } from 'utils/exchanges';
import { getUserAtsPairs } from 'utils/user-ats-pairs';


const ExportModal = ({ open, onClose, setPopUpLevel, setPopUpText, setPopUpDuration }) => {

    const { t } = useTranslation();

    const [userExchanges,] = useState(getUserConnectedExchanges({}));
    const [userAtsPairs,] = useState(getUserAtsPairs({}));

    const { RangePicker } = DatePicker;

    const [confirmButtonState, setConfirmButtonState] = useState('disabled');

    const [exportExchangesFilter, setExecutionsListExchangesFilter] = useState([]);
    const [exportPairsFilter, setExecutionsListPairsFilter] = useState([]);
    const [exportTimeframesFilter, setExecutionsListTimeframesFilter] = useState([]);
    const [exportHasSignalOnly, setExecutionsListHasSignalOnly] = useState(false);
    const [exportDateRangeFilter, setExecutionsListDateRangeFilter] = useState([]);
    const [exportFileType, setExportFileType] = useState('csv');

    const closeSvg = <CloseSvg className='icon-svg' />;
    const fileCsvSvg = <FileCsvSvg />;
    const fileXlsxSvg = <FileXlsxSvg />;
    const fileJsonSvg = <FileJsonSvg />;
    const fileXmlSvg = <FileXmlSvg />;


    useEffect(() => {
        if (exportExchangesFilter?.length > 0 &&
            exportPairsFilter.length > 0 &&
            exportTimeframesFilter.length > 0 &&
            exportDateRangeFilter.length > 0)
            setConfirmButtonState('enabled');
        else
            setConfirmButtonState('disabled');

    }, [exportExchangesFilter,
        exportPairsFilter,
        exportTimeframesFilter,
        exportHasSignalOnly,
        exportDateRangeFilter
    ]);


    const clearForm = () => {
        setConfirmButtonState('disabled');
        setExecutionsListDateRangeFilter([]);
        setExecutionsListExchangesFilter([]);
        setExecutionsListPairsFilter([]);
        setExecutionsListTimeframesFilter([]);
        setExecutionsListHasSignalOnly(false);
    };


    const handleConfirm = async (event) => {
        event.preventDefault();

        setConfirmButtonState('loading');

        let [since, until] = exportDateRangeFilter;

        try {
            if (since) since = since.valueOf();
            if (until) until = until.valueOf();

            const props = {
                since,
                until,
                exchanges: exportExchangesFilter,
                pairs: exportPairsFilter,
                timeframes: exportTimeframesFilter,
                hasSignalsOnly: exportHasSignalOnly,
                fileType: exportFileType
            };

            const result = await atsReportsExportsMePost(props);

            if (result.isSuccess) {
                setConfirmButtonState('success');
                setPopUpText(t('sucess-export-report'));
                setPopUpLevel('success');
                setPopUpDuration(10000);

                onClose();
            } else {
                setConfirmButtonState('error');
                setPopUpText(t('error-export-report'));
                setPopUpLevel('error');
                setPopUpDuration(3000);
            }
        } catch (error) {
            setPopUpText(t('unexpected-error'));
            setPopUpLevel('error');
            setPopUpDuration(3000);
        } finally {
            handleCancel();
        }
    };


    const handleCancel = () => {
        clearForm();
        onClose();
    };


    const buildExecutionsListExchangesFilter = () =>
        userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug,
        }));


    const buildExecutionsListPairsFilter = () => {
        let pairs = userAtsPairs.map((pair) => pair.split(':')[1]);

        if (pairs.length == 0) return [];

        // Remover items repetidos da lista
        pairs = [...new Set(pairs)];

        return pairs.map((pair) => ({
            label: pair,
            value: pair,
        }));
    };


    const buildExecutionsListTimeframesFilter = () =>
        timeframes.map((timeframe) => ({
            label: timeframe,
            value: timeframe,
        }));


    const handleChangeFileType = (e) => {
        setExportFileType(e.target.value);
    };


    return (
        <Modal
            title={<Title variation='secondary' txt={t('execution-report')} />}
            open={open}
            onCancel={handleCancel}
            footer={null}
            closeIcon={closeSvg}
            centered
        >
            <hr />

            <form name='add-exchange-connection' onSubmit={handleConfirm}>

                <Row id='executions-filters'
                    fill='width'
                >
                    <Select
                        className='w-8'
                        mode='tags'
                        maxCount={20}
                        placeholder={t('exchange.p')}
                        variant='borderless'
                        value={exportExchangesFilter}
                        options={buildExecutionsListExchangesFilter()}
                        onChange={(value) => setExecutionsListExchangesFilter(value)}
                    />

                    <Select
                        className='w-8'
                        mode='tags'
                        maxCount={20}
                        placeholder={t('pair.p')}
                        variant='borderless'
                        value={exportPairsFilter}
                        options={buildExecutionsListPairsFilter()}
                        onChange={(value) => setExecutionsListPairsFilter(value)}
                    />

                    <Select
                        className='w-8'
                        mode='tags'
                        placeholder={t('timeframe.p')}
                        variant='borderless'
                        value={exportTimeframesFilter}
                        options={buildExecutionsListTimeframesFilter()}
                        onChange={(value) => setExecutionsListTimeframesFilter(value)}
                    />
                </Row>

                <Row
                    fill='width'
                >
                    <Checkbox
                        className='w-8'
                        checked={exportHasSignalOnly}
                        onChange={() => setExecutionsListHasSignalOnly(!exportHasSignalOnly)}
                    >
                        {t('with-signal-only')}
                    </Checkbox>

                    <RangePicker
                        className='w-16'
                        showTime={{ format: 'HH:mm' }}
                        variant='borderless'
                        onChange={(value) => setExecutionsListDateRangeFilter(value)}
                        format="YYYY-MM-DD HH:mm"
                        value={exportDateRangeFilter}
                    />
                </Row>

                <hr />

                <Title variation='tertiary' txt={t('export-file-type')} />

                <Radio.Group
                    className='fill-width'
                    onChange={handleChangeFileType}
                    value={exportFileType}
                >
                    <Radio.Button
                        className='w-12 fill-height'
                        value='csv'
                    >
                        <Row m='pri-ver'> {fileCsvSvg} <b>CSV</b> </Row>
                    </Radio.Button>

                    <Radio.Button
                        className='w-12 fill-height'
                        value='json'
                    >
                        <Row m='pri-ver'>{fileJsonSvg} <b>JSON</b></Row>
                    </Radio.Button>

                    {/* <Radio.Button
                        className='w-6 fill-height'
                        value='xlsx'
                    >
                        <Row> {fileXlsxSvg} <b>XLSX</b> </Row>
                    </Radio.Button> */}

                    {/* <Radio.Button
                        className='w-6 fill-height'
                        value='xml'
                    >
                        <Row>{fileXmlSvg} <b>XML</b></Row>
                    </Radio.Button> */}
                </Radio.Group>

                <hr />

                <Row fill='width' j='end'>
                    <Button id='add-exchange-connection-confirm-button'
                        state={confirmButtonState}
                        type='submit'
                    >
                        {t('Confirm')}
                    </Button>
                </Row>
            </form>
        </Modal>
    );
};

export default ExportModal;
